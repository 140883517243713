import { gql } from '@apollo/client';

export const UPDATE_REWARD_COUPON_DETAILS_MUTATION = gql`
  mutation updateRwcCouponTemplate($params: UpdateRwcCouponTemplateParams!) {
    updateRwcCouponTemplate(params: $params) {
      deal {
        couponType
        couponValue
        minimumOrder
        maxDiscount
      }
      promotion {
        title
        description
      }
      countryCode
    }
  }
`;
