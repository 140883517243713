import { HelpCircle } from '@hexa-ui/icons';
import EditActions, { IEditActions } from '../EditActions/EditActions';
import HeadingStyled from '../HeadingStyled/HeadingStyled';
import StatusBadge from '../StatusBadge/StatusBadge';
import { Divider, HeadingCard } from './CardHeader.styles';

interface ICardHeader extends Partial<IEditActions> {
  title: string;
  helpText?: string;
  withBadge?: boolean;
  badgeText?: string;
  editActions?: boolean;
  customStyle?: any;
  testSufix?: string;
  dateTestId?: string;
}

const CardHeader = ({
  title,
  helpText = '',
  withBadge = false,
  badgeText = '',
  editActions = false,
  isEdit = false,
  disabledEdit,
  disabledClose,
  disabledSave,
  customStyle = false,
  testSufix,
  dateTestId,
  onEdit = () => {
    return;
  },
  onClose = () => {
    return;
  },
  onSave = () => {
    return;
  },
}: ICardHeader): React.JSX.Element => {
  return (
    <HeadingCard data-testid={'card-header'} headingCustom={customStyle}>
      <HeadingStyled
        dataTestId={dateTestId ?? 'card-title'}
        dataTestIdTooltip="help-icon"
        title={title}
        size="H3"
        placement="right"
        tooltipText={helpText}
        tooltipIcon={<HelpCircle />}
        cardHeader
      >
        {withBadge && (
          <>
            <Divider orientation="vertical" />
            <StatusBadge statusTitle={badgeText} />
          </>
        )}
      </HeadingStyled>
      {editActions && (
        <EditActions
          isEdit={isEdit}
          disabledEdit={disabledEdit}
          disabledClose={disabledClose}
          disabledSave={disabledSave}
          testSufix={testSufix}
          onEdit={onEdit}
          onClose={onClose}
          onSave={onSave}
        />
      )}
    </HeadingCard>
  );
};

export default CardHeader;
