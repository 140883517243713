import { EyeOff, EyeOn } from '@hexa-ui/icons';
import { useState } from 'react';
import { Text } from './HiddenText.styles';

const HiddenText = ({ text }: { text: string }) => {
  const [isHidden, setIsHidden] = useState(true);
  const textRender = isHidden ? text.replace(/./g, '*') : text;
  return (
    <Text>
      {textRender}
      {isHidden ? (
        <EyeOn onClick={() => setIsHidden(false)} />
      ) : (
        <EyeOff onClick={() => setIsHidden(true)} />
      )}
    </Text>
  );
};

export default HiddenText;
