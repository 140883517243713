import CardHeader from '@/components/CardHeader/CardHeader';
import { CouponTemplate } from '@/types/mgmTemplate.types';
import { LoadingDots } from '@hexa-ui/components';
import { useState } from 'react';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import StatusBuzz from '../StatusBuzz/StatusBuzz';
import { Container } from './CouponReferralAndRewardMainInfoCard.styles';
import EditInfo from './EditInfo';
import ViewInfo from './ViewInfo';

interface IMainInfoCard {
  isLoading: boolean;
  hasError: boolean;
  couponTemplate: CouponTemplate;
  disableActions?: boolean;
  onUpdateTemplate?: () => Promise<void>;
  onCancelEdit?: () => void;
  minimumValueForOrder: number;
}

const CouponReferralAndRewardMainInfoCard = ({
  couponTemplate,
  isLoading,
  hasError,
  disableActions,
  onUpdateTemplate,
  onCancelEdit,
  minimumValueForOrder,
}: IMainInfoCard) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const { trigger } = useFormContext();

  const { formatMessage } = useIntl();

  const handleSetEditMode = (edit: boolean) => {
    if (edit) trigger();
    setIsEditMode(edit);
  };

  const handleCloseEdit = () => {
    onCancelEdit?.();
    setIsEditMode(false);
  };

  const handleOnEditSave = async () => {
    await onUpdateTemplate?.();
    setIsEditMode(false);
  };

  const loadingData = <LoadingDots className="loading-data" size="xlarge" />;

  const errorData = (
    <StatusBuzz
      className="error-buzz"
      status="ERROR"
      title={formatMessage({ id: `REFERRAL_REWARD_COUPON.ERROR.TITLE` })}
      text={formatMessage({ id: `REFERRAL_REWARD_COUPON.ERROR.DESCRIPTION` })}
    />
  );

  const renderContent = () => {
    if (isLoading) return loadingData;
    if (hasError) return errorData;
    if (isEditMode) return <EditInfo minimumValueForOrder={minimumValueForOrder} />;
    return <ViewInfo couponTemplate={couponTemplate} />;
  };

  return (
    <Container elevated={'small'} border={'medium'} data-testid="main-info-card">
      <CardHeader
        title={formatMessage({ id: 'REFERRAL_REWARD_COUPON.MAIN_INFORMATION' })}
        isEdit={isEditMode}
        editActions={!isLoading && !hasError}
        disabledSave={disableActions}
        onEdit={handleSetEditMode}
        onClose={handleCloseEdit}
        onSave={handleOnEditSave}
      />
      {renderContent()}
    </Container>
  );
};
export default CouponReferralAndRewardMainInfoCard;
