import { CouponTypeMutationEnum } from '@/types/coupon.types';
import { CouponTemplate } from '@/types/mgmTemplate.types';
import { getValueOrNull } from './stringUtils';

const referralPayload = (formData: CouponTemplate) => {
  return {
    variables: {
      title: formData.title,
      description: formData.description,
      restrictions: {
        couponType: formData.restrictions.couponType,
        couponValue: Number(formData.restrictions.couponValue),
        orderMinimum: Number(formData.restrictions.orderMinimum),
        maxDiscount: getValueOrNull(
          formData.restrictions.couponType === CouponTypeMutationEnum.PERCENT_OFF &&
            !!formData.restrictions.maxDiscount,
          Number(formData.restrictions.maxDiscount)
        ),
      },
    },
  };
};

const RewardPayload = (formData: CouponTemplate) => {
  return {
    variables: {
      params: {
        title: formData.title,
        description: formData.description,
        couponType: formData.restrictions.couponType,
        couponValue: Number(formData.restrictions.couponValue),
        minimumOrder: Number(formData.restrictions.orderMinimum),
        maxDiscount: getValueOrNull(
          formData.restrictions.couponType === CouponTypeMutationEnum.PERCENT_OFF &&
            !!formData.restrictions.maxDiscount,
          Number(formData.restrictions.maxDiscount)
        ),
      },
    },
  };
};

export { referralPayload, RewardPayload };
