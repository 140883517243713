import CardHeader from '@/components/CardHeader/CardHeader';
import { useIntl } from 'react-intl';
import { Container, Option, Select, SelectField } from './CountryCard.styles';

interface CountryCardInterface {
  countries: string[];
  onCountryChange: (countryCode: string) => void;
  currentCountry: string | undefined;
}

const CountryCard = ({ countries, onCountryChange, currentCountry }: CountryCardInterface) => {
  const { formatMessage } = useIntl();

  const defaultCountry = currentCountry || countries[0];

  const handleCountrySelect = (countryCode: string) => {
    onCountryChange(countryCode);
  };

  return (
    <Container elevated={'small'} border={'medium'} data-testid="country-card">
      <CardHeader title={formatMessage({ id: `ENTITIES.COUPONS.PROPERTIES.COUNTRY` })} />

      <SelectField>
        <Select
          key={defaultCountry}
          placeholder={formatMessage({ id: `FILTERS.PLACEHOLDERS.COUNTRY` })}
          value={defaultCountry}
          shape="pill"
          size="medium"
          data-testid="country-card-countries-select"
          disabled={false}
          onChange={handleCountrySelect}
          defaultValue={defaultCountry}
        >
          {countries.map((countryCode) => (
            <Option
              value={countryCode}
              key={countryCode}
              data-testid={`countries-select-option-${countryCode}`}
            >
              {formatMessage({ id: `COUNTRIES.${countryCode}` })}
            </Option>
          ))}
        </Select>
      </SelectField>
    </Container>
  );
};
export default CountryCard;
