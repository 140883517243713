import { Card as HexaUiCard } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Container = styled(HexaUiCard, {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '$8',
  marginTop: '$4',
  minHeight: 'fit-content',
  variants: {
    isEmpty: { true: { table: { height: 400 } } },
  },
});

export const CenterColumn = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  width: 81,
  textAlign: 'center',
  textTransform: 'capitalize',
});
