import { styled } from '@hexa-ui/theme';

export const CopyContainer = styled('span', {
  cursor: 'pointer',
  display: 'flex',
  alignItems: 'center',
  userSelect:  'none',

  i: {
    display: 'flex',
    alignItems: 'center',
  },

  svg: {
    width: '20px',
    height: '20px',
    color: '$semanticInfoText',
    marginLeft: '$6',
  },
});

export const Label = styled('span', {
  fontWeight: 500,
  marginLeft: '$2',
});
