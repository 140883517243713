import { CouponTypeMutationEnum } from '@/types/coupon.types';
import { CouponTemplate } from '@/types/mgmTemplate.types';
import { Heading } from '@hexa-ui/components';
import { Cash, Percent } from '@hexa-ui/icons';
import { useFormContext } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Item } from '../Grid/Grid.styles';
import InfoField from '../InfoField/InfoField';
import InputField from '../InputField/InputField';
import SelectableCard from '../SelectableCard/SelectableCard';
import TextAreaField from '../TextArea/TextAreaField';
import {
  EditBottomWrapper,
  InputsWrapper,
  RestrictionsWrapper,
  TypesCardWrapper,
} from './CouponReferralAndRewardMainInfoCard.styles';

type FormKeys =
  | 'title'
  | 'description'
  | 'restrictions.couponType'
  | 'restrictions.couponValue'
  | 'restrictions.orderMinimum'
  | 'restrictions.maxDiscount'
  | 'restrictions.monthOfExpire';

export const sanitizeDecimal = (value: string): string => {
  // Regex to ensure the number format includes up to two decimal places
  const regex = /^(\d+)(?:[.,](\d{0,2}))?.*$/;

  // Remove any non-numeric characters or decimal separators
  const sanitizedValue = value
    .replace(/[^0-9.,]/g, '')
    .replace(regex, (_, integerPart, decimalPart = '') => {
      // Return the integer part and, if present, the decimal part with up to two decimal places
      return `${integerPart}${decimalPart ? '.' + decimalPart : ''}`;
    });

  return sanitizedValue || value;
};

const EditInfo = ({ minimumValueForOrder }: { minimumValueForOrder: number }) => {
  const { formatMessage } = useIntl();
  const {
    register,
    setValue,
    watch,
    trigger,
    formState: { errors },
  } = useFormContext<CouponTemplate>();

  const handleTypeClick = (type: CouponTypeMutationEnum) => () => {
    setValue('restrictions.couponType', type, { shouldValidate: true, shouldTouch: true });
    if (type === CouponTypeMutationEnum.AMOUNT_OFF) {
      setValue('restrictions.maxDiscount', undefined);
    }
    trigger();
  };

  const [title, description, type, couponValue, orderMinimum, maxDiscount, monthOfExpire] = watch([
    'title',
    'description',
    'restrictions.couponType',
    'restrictions.couponValue',
    'restrictions.orderMinimum',
    'restrictions.maxDiscount',
    'restrictions.monthOfExpire',
  ]);

  const updateDescription = async (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setValue('description', event.target.value, { shouldValidate: true, shouldTouch: true });
  };

  const getSufixCouponValue = () => {
    if (type === CouponTypeMutationEnum.AMOUNT_OFF) return '$';
    if (type === CouponTypeMutationEnum.PERCENT_OFF) return '%';
    return undefined;
  };

  const handleSetNumberValue = (key: FormKeys) => (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(key, sanitizeDecimal(event.target.value), {
      shouldValidate: true,
      shouldTouch: true,
    });
  };

  return (
    <div>
      <InputsWrapper>
        <InputField
          required
          dataTestId="input-title"
          value={title}
          label={formatMessage({ id: 'REFERRAL_REWARD_COUPON.TITLE' })}
          hasError={Boolean(errors?.title?.message)}
          errorText={errors?.title?.message as string}
          maxLength={50}
          {...register('title')}
        />
        <TextAreaField
          required
          dataTestId="text-area-description"
          value={description}
          label={formatMessage({ id: 'REFERRAL_REWARD_COUPON.DESCRIPTION' })}
          hasError={Boolean(errors?.description?.message)}
          errorText={errors?.description?.message as string}
          width="100%"
          onChange={updateDescription}
          maxLength={2000}
        />
      </InputsWrapper>
      <EditBottomWrapper>
        <Heading size="H4">{formatMessage({ id: 'REFERRAL_REWARD_COUPON.RESTRICTIONS' })}</Heading>
        <Heading size="H5">
          {formatMessage({ id: 'ENTITIES.COUPONS.PROPERTIES.COUPON_TYPE' })}
        </Heading>
        <TypesCardWrapper>
          <Item md={3} xs={12}>
            <SelectableCard
              dataTestId="radio-type-nominal"
              label={formatMessage({ id: 'ENTITIES.COUPONS.PROPERTIES.TYPE.NOMINAL' })}
              description={formatMessage({
                id: 'ENTITIES.COUPONS.PROPERTIES.TYPE.NOMINAL_EXAMPLE',
              })}
              icon={<Cash />}
              onClick={handleTypeClick(CouponTypeMutationEnum.AMOUNT_OFF)}
              className={type === CouponTypeMutationEnum.AMOUNT_OFF ? 'active_type' : ''}
            />
          </Item>
          <Item md={3} xs={12}>
            <SelectableCard
              dataTestId="radio-type-percentage"
              label={formatMessage({ id: 'ENTITIES.COUPONS.PROPERTIES.TYPE.PERCENTAGE' })}
              description={formatMessage({
                id: 'ENTITIES.COUPONS.PROPERTIES.TYPE.PERCENTAGE_EXAMPLE',
              })}
              icon={<Percent />}
              onClick={handleTypeClick(CouponTypeMutationEnum.PERCENT_OFF)}
              className={type === CouponTypeMutationEnum.PERCENT_OFF ? 'active_type' : ''}
            />
          </Item>
        </TypesCardWrapper>
        <RestrictionsWrapper>
          <InputField
            dataTestId="input-coupon-price"
            required
            label={formatMessage({ id: 'REFERRAL_REWARD_COUPON.COUPON_VALUE_LABEL' })}
            value={couponValue}
            hasError={Boolean(errors?.restrictions?.couponValue?.message)}
            errorText={errors?.restrictions?.couponValue?.message as string}
            placeholder={`0${formatMessage({ id: 'MONEY_MASK.RADIX' })}00`}
            sufix={getSufixCouponValue()}
            type="number"
            onChange={handleSetNumberValue('restrictions.couponValue')}
          />
          <InputField
            dataTestId="input-order-minimum"
            required
            label={formatMessage({ id: 'ENTITIES.COUPONS.PROPERTIES.ORDER_MINIMUM' })}
            value={orderMinimum}
            min={minimumValueForOrder}
            hint={formatMessage({ id: 'REFERRAL_REWARD_COUPON.ORDER_MINIMUM_HINT' })}
            hasError={Boolean(errors?.restrictions?.orderMinimum?.message)}
            errorText={errors?.restrictions?.orderMinimum?.message as string}
            type="number"
            onChange={handleSetNumberValue('restrictions.orderMinimum')}
          />
          {type === CouponTypeMutationEnum.PERCENT_OFF && (
            <InputField
              dataTestId="input-max-discount"
              required
              label={formatMessage({ id: 'ENTITIES.COUPONS.PROPERTIES.MAX_DISCOUNT' })}
              value={maxDiscount ?? ''}
              hasError={Boolean(errors?.restrictions?.maxDiscount?.message)}
              errorText={errors?.restrictions?.maxDiscount?.message as string}
              type="number"
              onChange={handleSetNumberValue('restrictions.maxDiscount')}
            />
          )}
        </RestrictionsWrapper>
        {Boolean(monthOfExpire) && (
          <InfoField
            dataTestId="main-info-expiration-period"
            title={formatMessage({ id: 'REFERRAL_REWARD_COUPON.EXPIRATION_PERIOD' })}
            text={`${monthOfExpire} ${formatMessage({
              id: 'REFERRAL_REWARD_COUPON.PLURAL_EXPIRATION',
            })}`}
          />
        )}
      </EditBottomWrapper>
    </div>
  );
};

export default EditInfo;
