import { gql } from '@apollo/client';

export const GET_RC_LOG_LIST_QUERY = gql`
  query ($limit: Float, $page: Float) {
    getRcLogList(limit: $limit, page: $page) {
      logs {
        id
        user
        createdAt
        operation
        differences
      }
      paginate {
        hasNext
        total
        page
      }
    }
  }
`;
