import { Check, Copy as CopyIcon } from '@hexa-ui/icons';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { CopyContainer, Label } from './Copy.styles';

interface ICopy {
  value: string;
  label?: string;
}

const Copy = ({ value, label }: ICopy): React.JSX.Element => {
  const { formatMessage } = useIntl();
  const [copied, setCopied] = useState<boolean>(false);

  return (
    <CopyContainer
      data-testid={'copy-container'}
      onClick={() => {
        navigator.clipboard.writeText(value);
        setCopied(true)
        setTimeout(() => {
          setCopied(false)
        }, 3000);
      }}
    >
      <i data-testid={'copy-icon'}>
        {copied ? <Check /> : <CopyIcon />}
      </i>
      <Label data-testid={'copy-label'}>{label ?? formatMessage({ id: 'ACTIONS.COPY' })}</Label>
    </CopyContainer>
  );
};

export default Copy;
