import { EmptyBuzz } from '@/assets/EmptyBuzz';
import { IFullLog, LogsList } from '@/types/mgm.types';
import { PaginationProps, Table } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import CardHeader from '../CardHeader/CardHeader';
import StatusBuzz from '../StatusBuzz/StatusBuzz';
import { Container } from './LastAlterations.styles';
import useColumns from './useColumns/useColumns';

interface LastAlterationsProps {
  data: LogsList[];
  isLoading: boolean;
  pagination: Partial<PaginationProps>;
  getFullLog: (id: string) => Promise<IFullLog>;
  hasError: boolean;
}

const LastAlterations = ({
  data,
  pagination,
  isLoading,
  getFullLog,
  hasError,
}: LastAlterationsProps) => {
  const columns = useColumns({ getFullLog });
  const { formatMessage } = useIntl();

  const paginationTable = {
    showPageSizeSelector: true,
    pageSizeOptions: [10, 25, 50],
    quantityIndicatorIntl: formatMessage({
      id: 'COUPON_LIST.PAGINATION.QUANTITY_INDICATOR',
    }),
    pageSizeOptionsIntl: (option: number) =>
      formatMessage({ id: 'COUPON_LIST.PAGINATION.PAGE_SIZE_OPTIONS' }, { options: option }),
    ...pagination,
  };

  const contentData = (
    <Table
      loading={isLoading}
      pagination={paginationTable}
      loadingMessage=""
      data={data}
      columns={columns}
      emptyMessage={
        <StatusBuzz
          className="empty-feedback"
          status="EMPTY"
          image={EmptyBuzz()}
          title={formatMessage({ id: 'LAST_ALTERATIONS.EMPTY.TITLE' })}
          text={formatMessage({ id: 'LAST_ALTERATIONS.EMPTY.DESCRIPTION' })}
        />
      }
    />
  );

  const contentError = (
    <StatusBuzz
      className="error-buzz"
      status="ERROR"
      title={formatMessage({ id: `REFERRAL_REWARD_COUPON.ERROR.TITLE` })}
      text={formatMessage({ id: `REFERRAL_REWARD_COUPON.ERROR.DESCRIPTION` })}
    />
  );

  return (
    <Container
      isEmpty={data.length === 0}
      elevated={'small'}
      border={'medium'}
      data-testid="last-alterations-card"
    >
      <CardHeader title={formatMessage({ id: 'LAST_ALTERATIONS.TITLE' })} />
      {hasError ? contentError : contentData}
    </Container>
  );
};

export default LastAlterations;
