import { Badge } from '@hexa-ui/components';
import { IStatusBadge } from './StatusBadge.d';
import { Container } from './StatusBadge.styles';

const colors = {
  Active: 'green',
  Inactive: 'gray',
  Expired: 'gray',
  Activo: 'green',
  Inactivo: 'gray',
  Vencido: 'gray',
};

const StatusBadge = ({ statusTitle, style }: IStatusBadge): React.JSX.Element => {
  return (
    <Container>
      <Badge.Status
        style={style}
        color={(colors[statusTitle as keyof typeof colors] as any) || 'gray'}
        label={statusTitle}
        type="default"
        data-testid={'status-badge'}
        data-color={colors[statusTitle as keyof typeof colors]}
      />
    </Container>
  );
};

export default StatusBadge;
