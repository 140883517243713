import JSONViewerLib from 'react-json-view';

interface JSONViewerProps {
  data: object;
}

const JSONViewer = ({ data }: JSONViewerProps) => {
  return (
    <JSONViewerLib
      data-testid="json-viewer-lib"
      src={data}
      collapseStringsAfterLength={12}
      displayObjectSize={true}
      name={false}
      displayDataTypes={false}
    />
  );
};

export default JSONViewer;
