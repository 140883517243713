import { Paragraph as _Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Info = styled('div', {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'space-between',
  alignItems: 'flex-start',
  paddingBottom: '$6',
  marginBottom: 0,
});

export const InputContainer = styled('div', {
  marginTop: '$1',
  width: '100%',
});

export const LabelContainer = styled('div', {
  display: 'flex', flexDirection: 'row', alignItems: 'center',

  '[data-testid="tooltip"]': {
    lineHeight: '10px',
    marginLeft: '4px',
    color: '$neutral60'
  }

});

export const Label = styled(_Paragraph, {
  marginBottom: '0px !important',
  display: 'flex',
  alignItems: 'center',
  fontSize: '14px'
});

export const Paragraph = styled(_Paragraph, {
  marginBottom: '0px !important',
  display: 'flex',
  alignItems: 'center',
  flexWrap: 'wrap',

  i: {
    display: 'flex',
    alignItems: 'center',
  },
});
