import Can from '@/components/Can/Can';
import PageHeader from '@/components/PageHeader/PageHeader';
import RCIcon from '@/components/RCIcon/RCIcon';
import RWCIcon from '@/components/RWCIcon/RWCIcon';
import { COUPON_ROUTES } from '@/config/constants';
import { TOGGLE_LIST } from '@/config/featureToggles';
import { Card, Heading, Paragraph } from '@hexa-ui/components';
import { Search } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import {
  Button,
  CardInfoWrapper,
  Content,
  GroupCards,
  PageContainer,
} from './CouponMGMPage.styles';

const CouponMGMPage = () => {
  const navigate = useNavigate();
  const { formatMessage } = useIntl();
  const breadcrumbs = [
    {
      name: formatMessage({ id: 'HOMEPAGE.TITLE' }),
      active: false,
      href: COUPON_ROUTES.HOME,
    },
    {
      name: formatMessage({ id: 'MGM.BREADCRUMBS' }),
      active: true,
      href: COUPON_ROUTES.MGM,
    },
  ];

  const handleRedirectTo = (path: string) => () => {
    navigate(path);
  };

  return (
    <PageContainer data-testid="mgm-page">
      <PageHeader title={formatMessage({ id: 'MGM.TITLE' })} breadcrumbs={breadcrumbs} />
      <Content>
        <Heading alignment="center" size="H3">
          {formatMessage({ id: 'MGM.SELECT_TYPE' })}
        </Heading>
        <GroupCards>
          <Card
            onClick={handleRedirectTo(COUPON_ROUTES.MGM_REFERRAL_COUPON)}
            border="medium"
            elevated="large"
          >
            <RCIcon />
            <CardInfoWrapper>
              <Heading size="H4">{formatMessage({ id: 'MGM.RC.TITLE' })}</Heading>
              <Paragraph size="small">{formatMessage({ id: 'MGM.RC.DESCRIPTION' })}</Paragraph>
            </CardInfoWrapper>
          </Card>
          <Card
            onClick={handleRedirectTo(COUPON_ROUTES.MGM_REWARD_COUPON)}
            border="medium"
            elevated="large"
          >
            <RWCIcon />
            <CardInfoWrapper>
              <Heading size="H4">{formatMessage({ id: 'MGM.RWC.TITLE' })}</Heading>
              <Paragraph size="small">{formatMessage({ id: 'MGM.RWC.DESCRIPTION' })}</Paragraph>
            </CardInfoWrapper>
          </Card>
        </GroupCards>
        <Can featureToggle={TOGGLE_LIST.MGM_MANAGE_COUPONS_BY_USER}>
          <Button
            onClick={handleRedirectTo(COUPON_ROUTES.MGM_MANAGE)}
            variant="secondary"
            size="medium"
            leading
            icon={Search}
          >
            {formatMessage({ id: 'MGM.MANAGE_COUPONS_BY_USER' })}
          </Button>
        </Can>
      </Content>
    </PageContainer>
  );
};

export default CouponMGMPage;
