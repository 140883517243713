import { gql } from '@apollo/client';

export const GET_REFERRAL_COUPON_DETAILS_QUERY = gql`
  query {
    getRcCouponConfig {
      title
      description
      restrictions {
        couponType
        couponValue
        orderMinimum
        maxDiscount
        minimumValueForOrder
      }
    }
  }
`;
