import { QuestionBuzz } from '@/assets/QuestionBuzz';
import HeadingStyled from '@/components/HeadingStyled/HeadingStyled';
import { ErrorType } from '@/types/error';
import { LoadingBuzz, TextButton } from '@hexa-ui/components';
import { Plus } from '@hexa-ui/icons';
import React, { useMemo } from 'react';
import { Content, LoadingContainer, Paragraph } from './StatusBuzz.styles';

interface IStatusBuzzProps {
  image?: React.ReactNode;
  title?: string;
  text?: string;
  onClickFunction?: () => void;
  buttonText?: string;
  status: ErrorType;
  className?: string;
}

function StatusBuzz({
  image = QuestionBuzz(),
  onClickFunction,
  text,
  title,
  buttonText,
  status,
  className,
}: Readonly<IStatusBuzzProps>): React.JSX.Element {
  const Button = useMemo(() => {
    if (status === 'EMPTY') {
      return (
        <TextButton iconPosition="leading" size="large" icon={Plus} onClick={onClickFunction}>
          {buttonText}
        </TextButton>
      );
    } else {
      return (
        <TextButton className="blue" onClick={onClickFunction}>
          {buttonText}
        </TextButton>
      );
    }
  }, [status]);

  const displayGrayText = status === 'EMPTY' || status === 'LOADING';

  const isTypeLoading = status === 'LOADING';

  return (
    <Content className={className} id={'statusBuzz'} data-testid={`statusBuzz-${status}`}>
      {isTypeLoading ? (
        <LoadingContainer data-testid="loading-buzz">
          <LoadingBuzz size="xxlarge" />
        </LoadingContainer>
      ) : (
        image
      )}
      <HeadingStyled title={title} size={isTypeLoading ? 'H4' : 'H2'} />

      <Paragraph size={isTypeLoading ? 'small' : 'basis'} displayGray={displayGrayText}>
        {text}
      </Paragraph>
      {buttonText && Button}
    </Content>
  );
}

export default StatusBuzz;
