import { format, isDate } from 'date-fns';
import { es, ptBR } from 'date-fns/locale';
import enUS from 'date-fns/locale/en-US';
import { dateAsTextEnUs, dateAsTextPtBr } from './constants';

const locales = { ptBR, enUS, es } as any;

export const getDateFnsLocale = (lng?: string) => {
  return locales[setLngDate[lng ?? 'es-419']];
};

const dateOffset = (date: Date) => {
  return new Date(date.valueOf() + date.getTimezoneOffset() * 60 * 1000);
};
const setLngDate = {
  'pt-BR': 'ptBR',
  'es-419': 'es',
  'en-US': 'enUS',
} as any;

export function dateIsValid(dateString: string, lng?: string) {
  const regex = /^\d{2}\/\d{2}\/\d{4}$/;

  if (regex.exec(dateString) === null) {
    return false;
  }

  const [month, day, year] = dateString.split('/');

  const isoFormattedString = {
    'pt-BR': `${year}-${day}-${month}`,
    'es-419': `${year}-${day}-${month}`,
    'en-US': `${year}-${month}-${day}`,
  } as any;

  const date = new Date(isoFormattedString[lng ?? 'es-419']);

  const timestamp = date.getTime();

  if (typeof timestamp !== 'number' || Number.isNaN(timestamp)) {
    return false;
  }

  return date.toISOString().startsWith(isoFormattedString[lng ?? 'es-419']);
}

export function convertDateToCompleteText(date: Date, preferredLanguage?: string) {
  if (preferredLanguage === 'en-US') {
    return format(date, dateAsTextEnUs, { locale: enUS });
  }
  return format(date, dateAsTextPtBr, { locale: ptBR });
}

export function convertDateToDateTimeText(date: Date, lng?: string) {
  const setFormatDate = {
    'pt-BR': 'dd MMM, yyyy, HH:mm',
    'es-419': 'dd MMM, yyyy, HH:mm',
    'en-US': 'PPp',
  } as any;

  if (isDate(date)) {
    const locale = locales[setLngDate[lng ?? 'es-419']];

    return format(date, setFormatDate[lng ?? 'es-419'], {
      locale,
    });
  }
}

export function convertDateToDateText(date: Date, lng?: string) {
  const setFormatDate = {
    'pt-BR': 'dd MMMM, yyyy',
    'es-419': 'dd MMMM, yyyy',
    'en-US': 'MMMM dd, yyyy',
  } as any;

  if (isDate(date)) {
    const locale = locales[setLngDate[lng ?? 'es-419']];

    return format(date, setFormatDate[lng ?? 'es-419'], {
      locale,
    });
  }
}

export function convertDateToTimeText(date: Date, lng?: string) {
  const setFormatDate = {
    'pt-BR': 'HH:mm',
    'es-419': 'HH:mm',
    'en-US': 'p',
  } as any;

  if (isDate(date)) {
    const locale = locales[setLngDate[lng ?? 'es-419']];
    return format(date, setFormatDate[lng ?? 'es-419'], {
      locale,
    });
  }
}

export function formatDateToString(date: Date, lng?: string) {
  const dateLength = 2;
  const yearLength = 4;
  const day = date.getDate().toString().padStart(dateLength, '0');
  const month = (date.getMonth() + 1).toString().padStart(dateLength, '0');
  const year = date.getFullYear().toString().padStart(yearLength, '0');
  const isoFormattedString = {
    'pt-BR': `${day}/${month}/${year}`,
    'es-419': `${day}/${month}/${year}`,
    'en-US': `${month}/${day}/${year}`,
  } as any;

  return isoFormattedString[lng ?? 'es-419'];
}

export function formatToDateTime(value: string, lng: string) {
  const [month, day, year] = value.split('/');

  const isoFormattedString = {
    'pt-BR': `${year}-${day}-${month}`,
    'es-419': `${year}-${day}-${month}`,
    'en-US': `${year}-${month}-${day}`,
  } as any;

  return new Date(`${isoFormattedString[lng]}T00:00:00`);
}

export function convertDateToTextRequest(date: Date) {
  const dateLength = 2;
  const yearLength = 4;
  const day = date.getUTCDate().toString().padStart(dateLength, '0');
  const month = (date.getUTCMonth() + 1).toString().padStart(dateLength, '0');
  const year = date.getUTCFullYear().toString().padStart(yearLength, '0');

  return `${year}-${month}-${day}`;
}

export function convertDateFormat(value: string) {
  const [month, day, year] = value.split('/');

  return `${year}-${month}-${day}`;
}

export function formatDateStringByLocation(value: string, lng: string): string {
  if (lng === 'en-US') {
    const [month, day, year] = value.split('/');
    return `${year}-${(month || []).slice(-2)}-${(day || []).slice(-2)}`;
  } else {
    const [day, month, year] = value.split('/');
    return `${year}-${(month || []).slice(-2)}-${(day || []).slice(-2)}`;
  }
}

export function formatDatesWithLocationShortly(value: string | Date, lng?: string) {
  const setFormatDate = {
    'pt-BR': 'dd MMM, yyyy',
    'es-419': 'dd MMM, yyyy',
    'en-US': 'PP',
  };

  return parseDate(value, setFormatDate, lng);
}

export function formatDatesWithLocationOnlyDateFull(value: string, lng?: string) {
  const setFormatDate = {
    'pt-BR': 'PPP',
    'es-419': 'PPP',
    'en-US': 'PPP',
  };

  return parseDate(value, setFormatDate, lng);
}

export function formatDateOnPreview(value: string, lng?: string) {
  const [month, day, year] = value.split('/');
  const setFormatDate = {
    'pt-BR': 'PPP',
    'es-419': 'PPP',
    'en-US': 'PPP',
  } as any;

  const isoFormattedString = {
    'pt-BR': `${year}/${day}/${month}`,
    'es-419': `${year}/${day}/${month}`,
    'en-US': `${year}/${month}/${day}`,
  } as any;

  const date = new Date(isoFormattedString[lng ?? 'es-419']);

  if (isDate(date)) {
    const locale = locales[setLngDate[lng ?? 'es-419']];

    return format(date, setFormatDate[lng ?? 'es-419'], {
      locale,
    });
  }
}

export function formatPlaceholderDateInput(lng?: string) {
  const setPlaceholderDate = {
    'pt-BR': 'dd/mm/aaaa',
    'es-419': 'dd/mm/aaaa',
    'en-US': 'mm/dd/yyyy',
  } as any;
  return setPlaceholderDate[lng ?? 'es-419'];
}

function parseDate(value: string | Date, setFormatDate: { [x: string]: string }, lng?: string) {
  const parsedDate = new Date(value);

  parsedDate.setHours(0, 0, 0, 0);
  parsedDate.setUTCHours(0, 0, 0, 0);

  if (isDate(parsedDate)) {
    const locale = locales[setLngDate[lng ?? 'es-419']];

    return format(dateOffset(parsedDate), setFormatDate[lng ?? 'es-419'], {
      locale,
    });
  }
}

export function convertHoursTimeToSeconds(time: string) {
  if (time) {
    return parseInt(time.split(':')[0]) * 60 * 60 + parseInt(time.split(':')[1]) * 60;
  }
  return 0;
}

/**
 * Creates a time-of-day array with time gaps
 * @param \{gap?: number; locale?: string;} Optional values. Default for gap is 30 and for locale is en-US
 * @return { Array<string> } Time-of-day array with time gaps
 * @example Ex: ['00:00', '00:30', '01:00', '01:30', ...]
 */
export function generateTimes({
  gap = 30,
  lastDayMinute = true,
  locale = 'en-US',
}: {
  gap?: number;
  lastDayMinute?: boolean;
  locale?: string;
}): Array<string> {
  const times: Array<string> = [];
  const currentDate = new Date();
  for (let hour = 0; hour < 24; hour++) {
    for (let minute = 0; minute < 60; minute += gap) {
      const formattedHour = hour < 10 ? '0' + hour : hour;
      const formattedMinute = minute < 10 ? '0' + minute : minute;

      currentDate.setHours(Number(formattedHour));
      currentDate.setMinutes(Number(formattedMinute));
      times.push(convertDateToTimeText(currentDate, locale) as string);
    }
  }

  if (lastDayMinute) {
    currentDate.setHours(23);
    currentDate.setMinutes(59);
    times.push(convertDateToTimeText(currentDate, locale) as string);
  }

  return times;
}

export const getDateWithoutTimezone = (stringDate: string): Date => {
  const localTimezone = new Date();
  const offset = localTimezone.getTimezoneOffset() / 60;
  const newDate = new Date(Date.parse(stringDate));

  newDate.setHours(newDate.getHours() + offset);
  return newDate;
};

export const convertTime12to24 = (amPmTimeString: string): string => {
  const time = amPmTimeString.split(/[: ]/);
  let hours = parseInt(time[0]);
  const minutes = parseInt(time[1]);
  const shift = time[2];

  let finalHours;
  let finalMinutes;
  let finalReturn;

  if (shift === 'PM' && hours < 12) {
    hours += 12;
  }

  if (shift === 'AM' && hours === 12) hours = 0;

  const date = new Date('1970-01-01 ' + hours + ':' + minutes);
  finalHours = `${date.getHours().toString().padStart(2, '0')}`;
  finalMinutes = `${date.getMinutes().toString().padStart(2, '0')}`;
  finalReturn = `${finalHours}:${finalMinutes}`;

  return finalReturn;
};
