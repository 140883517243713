import { Divider as _Divider, Heading as _Heading } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';


export const HeadingCard = styled('div', {
  marginBottom: '$6',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  alignItems: 'center',

  svg: {
    width: '18px',
    height: '18px',
  },
  'button.close-button > svg': {
    width: '14px',
    height: '14px',
  },
  variants: {
    headingCustom: {
      true: {
        padding: '2rem 2rem 1.5rem 2rem',
        marginBottom: '0'
      },
    },
  },
});

export const Divider = styled(_Divider, {
  margin: '0 10px',
  height: '24px !important',
});

export const Heading = styled(_Heading, {
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  justifyContent: 'space-between',

  i: {
    width: '1.25rem',
    height: '1.25rem',
    display: 'flex',
    alignItems: 'center',
  },

  svg: {
    width: '1.25rem',
    height: '1.25rem',
    color: '$semanticInfoText',
    marginLeft: '$2',
  },
});
