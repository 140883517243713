import { Paragraph } from '@hexa-ui/components';
import { Card, Label } from './SelectableCard.styles';

interface ISelectableCard {
  children?: React.ReactNode;
  className: string;
  dataTestId: string;
  description?: string;
  icon?: React.ReactNode;
  label: string;
  onClick: () => void;
  tabIndex?: number;
}

const SelectableCard = ({
  className,
  children,
  dataTestId,
  description,
  icon,
  label,
  tabIndex = 0,
  onClick,
}: ISelectableCard) => {
  return (
    <Card
      border={'medium'}
      elevated={'small'}
      className={className}
      data-testid={dataTestId}
      onClick={onClick}
      tabIndex={tabIndex}
    >
      {children}
      {icon}
      <Label size="basis" weight="medium">
        {label}
      </Label>
      <Paragraph size="small" style={{ marginTop: '12px' }}>
        {description}
      </Paragraph>
    </Card>
  );
};

export default SelectableCard;
