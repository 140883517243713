import { Button as _Button } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const PageContainer = styled('div', {
  paddingTop: '$6',
  display: 'flex',
  flexWrap: 'wrap',
  flexDirection: 'column',
  gap: '$4',
});

export const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  maxWidth: '1000px',
  alignSelf: 'center',
  width: '100%',
  marginTop: '67px',
});

export const Button = styled(_Button, {
  width: 'max-content',
  alignSelf: 'center',
  marginTop: 56,
});

export const GroupCards = styled('div', {
  display: 'flex',
  gap: '$6',
  marginTop: '40px',
  alignItems: 'center',
  '& > div': {
    cursor: 'pointer',
    padding: '$8',
    display: 'flex',
    flexDirection: 'row',
    gap: '16px',
    '&:hover': { boxShadow: '$8' },
    width: 489,
    '@media (max-width: 550px)': {
      width: '100%',
    },
  },
  svg: {
    minWidth: '72px',
    minHeight: '55px',
  },
  '@media (max-width: 880px)': {
    flexWrap: 'wrap',
    flexDirection: 'column',
  },
});

export const CardInfoWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$2',
});
