import { CouponTypeMutationEnum } from '@/types/coupon.types';
import { CouponTemplate } from '@/types/mgmTemplate.types';
import { getValueOrNull } from '@/utils/stringUtils';
import { useIntl } from 'react-intl';

const useMGMTemplateRestrictions = ({ couponTemplate }: { couponTemplate: CouponTemplate }) => {
  const { formatMessage } = useIntl();

  const getRewardCouponExpirationInfo = (months: number) => {
    if (!months) return null;

    if (months > 1) {
      return `${months} ${formatMessage({ id: 'REFERRAL_REWARD_COUPON.PLURAL_EXPIRATION' })}`;
    }

    return `${months} ${formatMessage({ id: 'REFERRAL_REWARD_COUPON.SINGULAR_EXPIRATION' })}`;
  };

  const restrictionsList = [
    {
      label: formatMessage({ id: 'ENTITIES.COUPONS.PROPERTIES.COUPON_TYPE' }),
      value: getValueOrNull(
        !!couponTemplate?.restrictions.couponType,
        formatMessage({
          id: `ENTITIES.COUPONS.PROPERTIES.TYPE.${couponTemplate?.restrictions.couponType}`,
        })
      ),
      testId: 'main-info-coupon-type',
    },
    {
      label: formatMessage({ id: 'REFERRAL_REWARD_COUPON.COUPON_VALUE_LABEL' }),
      value: getValueOrNull(
        !Number.isNaN(couponTemplate?.restrictions.couponValue),
        String(couponTemplate?.restrictions.couponValue.toFixed(2))
      ),
      testId: 'main-info-coupon-price',
    },
    {
      label: formatMessage({ id: 'ENTITIES.COUPONS.PROPERTIES.ORDER_MINIMUM' }),
      value: getValueOrNull(
        !Number.isNaN(couponTemplate?.restrictions.orderMinimum),
        String(couponTemplate?.restrictions.orderMinimum.toFixed(2))
      ),
      testId: 'main-info-order-minimum',
    },
    {
      label: formatMessage({ id: 'ENTITIES.COUPONS.PROPERTIES.MAX_DISCOUNT' }),
      value: getValueOrNull(
        !Number.isNaN(couponTemplate?.restrictions.maxDiscount) &&
          couponTemplate?.restrictions?.couponType === CouponTypeMutationEnum.PERCENT_OFF,
        String(couponTemplate?.restrictions.maxDiscount?.toFixed(2))
      ),
      testId: 'main-info-max-discount',
    },
    {
      label: formatMessage({ id: 'REFERRAL_REWARD_COUPON.EXPIRATION_PERIOD' }),
      value: getValueOrNull(
        !Number.isNaN(couponTemplate?.restrictions.monthOfExpire),
        getRewardCouponExpirationInfo(couponTemplate?.restrictions.monthOfExpire ?? 0)
      ),
      testId: 'main-info-expiration-period',
    },
  ];

  const restrictionsToDisplay = restrictionsList.filter(
    (item) => item.value !== undefined && item.value !== null
  );

  return { restrictions: restrictionsToDisplay };
};
export default useMGMTemplateRestrictions;
