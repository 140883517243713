import JSONViewer from '@/components/JSONViewer/JSONViewer';
import StatusBuzz from '@/components/StatusBuzz/StatusBuzz';
import { IFullLog } from '@/types/mgm.types';
import { IconButton, LoadingDots, Modal } from '@hexa-ui/components';
import { X } from '@hexa-ui/icons';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { ButtonClose, Container, Content, FullLogText, JSONTitle, Title } from './FullLog.styles';

interface FullLogProps {
  id: string;
  getFullLog: (id: string) => Promise<IFullLog>;
}

const { Root: ModalRoot } = Modal;

const FullLog = ({ getFullLog, id }: FullLogProps) => {
  const { formatMessage } = useIntl();
  const [open, setOpen] = useState(false);
  const [fullLog, setFullLog] = useState<IFullLog>({ oldVersion: {}, newVersion: {} });
  const [isLoading, setLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const handleCloseModal = () => {
    setOpen(false);
  };

  const handleOpenModal = async () => {
    try {
      setOpen(true);
      setLoading(true);
      const data = await getFullLog(id);
      if (!data) {
        throw new Error();
      }
      setFullLog(data);
    } catch (error) {
      setHasError(true);
    } finally {
      setLoading(false);
    }
  };

  const contentData = (
    <Content>
      <div>
        <JSONTitle weight="semibold">
          {formatMessage({ id: 'LAST_ALTERATIONS.FULL_LOG.OLD_JSON' })}
        </JSONTitle>
        <JSONViewer data={fullLog.oldVersion ?? {}} />
      </div>
      <div>
        <JSONTitle weight="semibold">
          {formatMessage({ id: 'LAST_ALTERATIONS.FULL_LOG.NEW_JSON' })}
        </JSONTitle>
        <JSONViewer data={fullLog.newVersion ?? {}} />
      </div>
    </Content>
  );

  const loadingData = <LoadingDots className="loading-data" size="xlarge" />;

  const contentError = (
    <StatusBuzz
      className="error-buzz"
      status="ERROR"
      title={formatMessage({ id: `REFERRAL_REWARD_COUPON.ERROR.TITLE` })}
      text={formatMessage({ id: `REFERRAL_REWARD_COUPON.ERROR.DESCRIPTION` })}
    />
  );

  const renderContent = () => {
    if (isLoading) return loadingData;
    if (hasError) return contentError;
    return contentData;
  };

  return (
    <Container>
      <FullLogText onClick={handleOpenModal} size="small">
        {formatMessage({ id: 'LAST_ALTERATIONS.TABLE.FULL_LOG' })}
      </FullLogText>
      {open && (
        <ModalRoot actions={null} open={open} onEscapeKeyDown={handleCloseModal}>
          <Title size="H2">
            {formatMessage({ id: 'LAST_ALTERATIONS.FULL_LOG.TITLE' })}
            <IconButton variant="tertiary" size="small" icon={X} onClick={handleCloseModal} />
          </Title>
          {renderContent()}
          <ButtonClose onClick={handleCloseModal}>
            {formatMessage({ id: 'LAST_ALTERATIONS.FULL_LOG.CLOSE_BUTTON' })}
          </ButtonClose>
        </ModalRoot>
      )}
    </Container>
  );
};

export default FullLog;
