import React from 'react';

export function QuestionBuzz() {
  return (
    <svg
      width="97px"
      height="127px"
      viewBox="0 0 97 127"
      version="1.1"
      data-testid="question-buzz"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>34D934DE-4F1E-4BBA-9B04-C294D05C982D</title>
      <defs>
        <filter
          x="-7.3%"
          y="-8.0%"
          width="114.6%"
          height="116.1%"
          filterUnits="objectBoundingBox"
          id="filter-1"
        >
          <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="0.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          x="-7.3%"
          y="-8.0%"
          width="114.6%"
          height="116.1%"
          filterUnits="objectBoundingBox"
          id="filter-2"
        >
          <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="0.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
        <filter
          x="-3.0%"
          y="-3.6%"
          width="105.9%"
          height="107.2%"
          filterUnits="objectBoundingBox"
          id="filter-3"
        >
          <feOffset dx="0" dy="0" in="SourceAlpha" result="shadowOffsetOuter1"></feOffset>
          <feGaussianBlur
            stdDeviation="0.5"
            in="shadowOffsetOuter1"
            result="shadowBlurOuter1"
          ></feGaussianBlur>
          <feColorMatrix
            values="0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.25 0"
            type="matrix"
            in="shadowBlurOuter1"
            result="shadowMatrixOuter1"
          ></feColorMatrix>
          <feMerge>
            <feMergeNode in="shadowMatrixOuter1"></feMergeNode>
            <feMergeNode in="SourceGraphic"></feMergeNode>
          </feMerge>
        </filter>
      </defs>
      <g id="1.-Billing-🇧🇷" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="0.2-BillingList---Empty-state" transform="translate(-715.000000, -516.000000)">
          <g id="Group-7-Copy-2" transform="translate(589.000000, 517.500000)">
            <g id="Group-4" transform="translate(128.000000, 0.000000)">
              <g id="Group-19" transform="translate(54.000000, 0.000000)">
                <g
                  id="Mascot-/--Accessories-/-Talk-Bubble---Right"
                  fill="#FFFFFF"
                  filter="url(#filter-1)"
                  transform="translate(2.897527, 1.023454)"
                >
                  <path
                    d="M27.4204947,2.44249065e-15 C31.206475,2.44249065e-15 34.2756184,2.96313036 34.2756184,6.61833689 L34.2756184,19.5388923 C34.2756184,23.1940988 31.206475,26.1572292 27.4204947,26.1572292 L6.19783394,26.1271996 C2.72020203,25.8078054 8.8817842e-16,22.9800447 8.8817842e-16,19.5388923 L8.8817842e-16,6.61833689 C8.8817842e-16,2.96313036 3.06914341,2.44249065e-15 6.85512367,2.44249065e-15 L27.4204947,2.44249065e-15 Z"
                    id="Path"
                  ></path>
                  <polygon
                    id="Triangle"
                    transform="translate(9.451662, 27.607699) scale(-1, 1) rotate(157.000000) translate(-9.451662, -27.607699) "
                    points="9.38230078 24.8827369 11.8769669 30.3326616 7.02635711 30.2720802"
                  ></polygon>
                </g>
                <g
                  id="?"
                  transform="translate(13.409000, 5.920000)"
                  fill="#000000"
                  fillOpacity="0.9"
                  fillRule="nonzero"
                >
                  <path d="M6.624,0 C7.792,0 8.844,0.164 9.78,0.492 C10.716,0.82 11.452,1.312 11.988,1.968 C12.524,2.624 12.792,3.44 12.792,4.416 C12.792,5.408 12.588,6.216 12.18,6.84 C11.772,7.464 11.22,7.936 10.524,8.256 C9.828,8.576 9.064,8.784 8.232,8.88 L7.992,10.944 L4.392,10.944 L4.416,7.296 C5.392,7.2 6.172,7.052 6.756,6.852 C7.34,6.652 7.76,6.396 8.016,6.084 C8.272,5.772 8.4,5.4 8.4,4.968 C8.4,4.44 8.232,4.032 7.896,3.744 C7.56,3.456 7.04,3.312 6.336,3.312 C5.648,3.312 5.104,3.516 4.704,3.924 C4.304,4.332 4.048,4.936 3.936,5.736 L0,4.368 C0.208,3.536 0.572,2.788 1.092,2.124 C1.612,1.46 2.324,0.94 3.228,0.564 C4.132,0.188 5.264,0 6.624,0 Z M6.24,12.264 C6.96,12.264 7.524,12.44 7.932,12.792 C8.34,13.144 8.544,13.64 8.544,14.28 C8.544,14.904 8.34,15.392 7.932,15.744 C7.524,16.096 6.96,16.272 6.24,16.272 C5.536,16.272 4.98,16.096 4.572,15.744 C4.164,15.392 3.96,14.904 3.96,14.28 C3.96,13.64 4.164,13.144 4.572,12.792 C4.98,12.44 5.536,12.264 6.24,12.264 Z"></path>
                </g>
              </g>
              <g id="Group-24" transform="translate(0.000000, 37.000000)">
                <g id="Group-22" transform="translate(12.000000, 19.000000)">
                  <g id="Group-19" transform="translate(29.000000, 0.000000)">
                    <g
                      id="Mascot-/--Accessories-/-Talk-Bubble---Right"
                      fill="#FFFFFF"
                      filter="url(#filter-2)"
                      transform="translate(2.897527, 1.023454)"
                    >
                      <path
                        d="M27.4204947,2.44249065e-15 C31.206475,2.44249065e-15 34.2756184,2.96313036 34.2756184,6.61833689 L34.2756184,19.5388923 C34.2756184,23.1940988 31.206475,26.1572292 27.4204947,26.1572292 L6.19783394,26.1271996 C2.72020203,25.8078054 8.8817842e-16,22.9800447 8.8817842e-16,19.5388923 L8.8817842e-16,6.61833689 C8.8817842e-16,2.96313036 3.06914341,2.44249065e-15 6.85512367,2.44249065e-15 L27.4204947,2.44249065e-15 Z"
                        id="Path"
                      ></path>
                      <polygon
                        id="Triangle"
                        transform="translate(9.451662, 27.607699) scale(-1, 1) rotate(157.000000) translate(-9.451662, -27.607699) "
                        points="9.38230078 24.8827369 11.8769669 30.3326616 7.02635711 30.2720802"
                      ></polygon>
                    </g>
                    <g id="build_black_24dp" transform="translate(10.290323, 4.000000)">
                      <polygon id="Path" points="0 0 20 0 20 20 0 20"></polygon>
                      <path
                        d="M18.8416667,15.825 L11.275,8.25833333 C12.05,6.30833333 11.65,4.00833333 10.075,2.425 C8.15833333,0.508333333 5.175,0.333333333 3.05,1.88333333 L6.25,5.09166667 L5.06666667,6.26666667 L1.875,3.075 C0.325,5.19166667 0.5,8.18333333 2.41666667,10.0916667 C3.96666667,11.6416667 6.225,12.05 8.15833333,11.325 L15.75,18.9166667 C16.075,19.2416667 16.6,19.2416667 16.925,18.9166667 L18.8416667,17 C19.175,16.6833333 19.175,16.1583333 18.8416667,15.825 Z M16.3416667,17.1583333 L8.45833333,9.275 C7.95,9.65 7.38333333,9.875 6.79166667,9.95833333 C5.65833333,10.125 4.46666667,9.78333333 3.6,8.91666667 C2.80833333,8.13333333 2.44166667,7.08333333 2.5,6.05 L5.075,8.625 L8.60833333,5.09166667 L6.03333333,2.51666667 C7.06666667,2.45833333 8.10833333,2.825 8.9,3.60833333 C9.8,4.50833333 10.1416667,5.75 9.93333333,6.90833333 C9.83333333,7.5 9.58333333,8.05 9.2,8.54166667 L17.075,16.4166667 L16.3416667,17.1583333 Z"
                        id="Shape"
                        fill="#000000"
                        fillRule="nonzero"
                      ></path>
                    </g>
                  </g>
                  <g
                    id="Group-21"
                    transform="translate(0.000000, 61.000000)"
                    fill="#000000"
                    fillOpacity="0.05"
                  >
                    <g id="Mascot-/--Accessories-/-Shadow">
                      <ellipse id="Oval" cx="30.5" cy="4" rx="30.5" ry="4"></ellipse>
                    </g>
                  </g>
                </g>
                <g
                  id="Mascot-/-Buzz-/--Eyes-Open---Gray-Wings"
                  filter="url(#filter-3)"
                  transform="translate(0.145185, 0.131505)"
                  fillRule="nonzero"
                >
                  <g id="ABI-Bees_Assets_Mascot-1_sRGB">
                    <path
                      d="M78.3438467,25.9850487 C83.6148286,30.4267373 84.717355,32.6731038 84.717355,37.4638637 C84.7177605,48.569757 75.6451012,51.3862373 69.8538001,51.3862373 C66.0205704,51.3862373 64.2820282,49.9221469 64.2820282,49.9221469 L59.7584036,12.6608335 L78.3438467,25.9850487 Z M6.37350826,25.9850487 C1.10252642,30.4267373 0,32.6731038 0,37.4638637 C-0.000405478625,48.569757 9.07225385,51.3862373 14.8635549,51.3862373 C18.6967846,51.3862373 20.4353268,49.9221469 20.4353268,49.9221469 L24.9589665,12.6608335 L6.37350826,25.9850487 Z"
                      id="Shape"
                      fill="#F0ECFC"
                    ></path>
                    <path
                      d="M65.0444992,50.4559713 C58.5334226,63.3128242 45.8479487,69.865 42.3608325,69.865 C38.9147413,69.865 26.4854511,63.4660862 19.9089188,50.9059348 L19.6770067,50.4551418 C29.8069036,58.9842632 54.8310549,59.0540696 65.0444992,50.4559713 Z M16.8577138,24.1967823 C16.8577138,24.1967823 23.7929,30.5295573 42.3608175,30.577318 C60.5573767,30.5305125 67.5818607,24.4475793 67.8555615,24.2042807 L67.8639213,24.1967823 C67.8158646,24.0054424 67.7663811,23.8179528 67.7155026,23.6327854 L67.8639663,24.1952898 C68.5622456,26.9519946 68.9587136,30.1803218 68.9587136,33.9698227 C68.9587136,35.9213794 68.8103903,37.7951038 68.5369482,39.5899476 L68.4285878,40.2593145 C68.3103082,40.3761641 60.7879588,47.6939743 42.361238,47.7491977 C23.9345172,47.6939743 16.4121528,40.3761641 16.2950146,40.2612398 C16.5248016,41.5989926 16.8247016,42.8917468 17.1853796,44.1395711 C16.8242511,42.8913247 16.5243511,41.598565 16.294564,40.260807 C15.9506731,38.2623187 15.7629515,36.165324 15.7629515,33.9698227 C15.7629515,30.1803069 16.1594195,26.9519797 16.8577438,24.1982748 Z M63.2936594,15.5406141 C55.9575028,21.2002788 28.1581116,21.1891952 21.2008404,15.3580613 L21.2008854,15.3222408 C21.2264156,5.58800598 31.629195,0 42.3608626,0 C53.0925302,0 63.4953096,5.58800598 63.5208397,15.3222408 L63.5208397,15.3580613 Z"
                      id="Combined-Shape"
                      fill="#000000"
                    ></path>
                    <path
                      d="M38.7804863,9.90344211 C38.5745032,11.6180226 37.6168227,12.5476853 36.5878982,12.425597 C35.5589586,12.3035086 34.8484249,11.1759075 35.0543931,9.46132695 C35.2603612,7.74674642 36.3048741,6.09444887 37.3337987,6.21653725 C38.3627232,6.33862563 38.9864695,8.18886158 38.7804863,9.90344211 Z M45.9406982,9.90344211 C46.1466813,11.6180226 47.1043618,12.5476853 48.1333013,12.425597 C49.1622259,12.3035086 49.8727746,11.1759075 49.6667915,9.46132695 C49.4608083,7.74674642 48.4163254,6.09444887 47.3873859,6.21653725 C46.3584613,6.33862563 45.7347301,8.18886158 45.9406982,9.90344211 Z"
                      id="Shape"
                      fill="#FFFFFF"
                    ></path>
                    <path
                      d="M42.361238,47.7491977 C23.9345172,47.6939743 16.4121528,40.3761641 16.2950146,40.2612398 C16.939275,44.0119351 18.1346861,47.4089024 19.6755049,50.4536493 C19.6755049,50.4536493 19.6755049,50.4536493 19.6770067,50.4551418 C29.8072293,58.9845374 54.8326642,59.0540741 65.0454844,50.4551418 C65.0469862,50.4536493 65.0469862,50.4536493 65.0469862,50.4536493 C66.587805,47.4089024 67.783216,44.0119351 68.4274615,40.2612398 C68.3103082,40.3761641 60.7879588,47.6939743 42.361238,47.7491977 Z M67.8639213,24.1952898 L67.8639213,24.1967823 C67.8639213,24.1967823 60.928735,30.5295573 42.3608175,30.577318 C23.7929,30.5295573 16.8577138,24.1967823 16.8577138,24.1967823 L16.8577138,24.1952898 C17.8188483,20.3968188 19.3521582,17.5446075 21.2008404,15.3580613 C28.2321251,21.2512285 56.551338,21.1998559 63.5207947,15.3580613 C64.4458866,16.4520657 65.2898829,17.71177 66.0257515,19.1714575 C66.7601184,20.63413 67.383354,22.2968155 67.8639213,24.1952898 Z"
                      id="Shape"
                      fill="#FFFF00"
                    ></path>
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
