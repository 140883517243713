import { Card as _Card, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Card = styled(_Card, {
  border: '2px solid #dadada',
  padding: '$4',
  height: '160px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  transition: 'all .1s',
  width: '100%',

  '&:hover': {
    border: '1px solid #202020',
    boxShadow: '0px 0px 10px 5px #00000010',
    cursor: 'pointer',
  },

  '&.active_type': {
    border: '2px solid #202020',
    boxShadow: '0px 0px 10px 5px #00000010',
  },

  '>h4': {
    fontSize: '18px !important',
  },
});

export const Label = styled(Paragraph, {
  fontSize: '18px !important',
  textAlign: 'center !important',
});
