import PageHeader from '@/components/PageHeader/PageHeader';
import { COUPON_ROUTES } from '@/config/constants';
import { Button, SegmentedControl } from '@hexa-ui/components';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import CodeMode from './CodeMode/CodeMode';
import FileMode from './FileMode/FileMode';
import { ActionButtonsWrapper, Container, Content, Label } from './ManageBlocklist.styles';

export enum AddCouponToBlocklistEnum {
  CODE = 'code',
  CSV = 'csv',
}

const ManageBlocklist = () => {
  const navigate = useNavigate();

  const [addCouponToBlockType, setAddCouponToBlockType] = useState<AddCouponToBlocklistEnum>(
    AddCouponToBlocklistEnum.CODE
  );

  const { formatMessage } = useIntl();

  const breadcrumbs = [
    {
      name: formatMessage({ id: 'HOMEPAGE.TITLE' }),
      active: false,
      href: COUPON_ROUTES.HOME,
    },
    {
      name: formatMessage({ id: 'MGM.BREADCRUMBS' }),
      active: false,
      href: COUPON_ROUTES.MGM,
    },
    {
      name: formatMessage({ id: 'MGM_MANAGEMENT.MANAGE_BLOCKLIST' }),
      active: true,
      href: COUPON_ROUTES.MGM_BLOCKLIST,
    },
  ];

  const handleGoBack = () => {
    navigate(COUPON_ROUTES.MGM_MANAGE);
  };

  const handleAddBlocklist = () => {
    //TODO send blocklist payload when backend is ready
  };

  return (
    <Container>
      <PageHeader
        title={formatMessage({ id: `MGM_MANAGEMENT.PAGE_TITLE` })}
        breadcrumbs={breadcrumbs}
      />
      <Content elevated={'small'} border={'medium'} data-testid="manage-blocklist-content">
        <Label>{formatMessage({ id: 'MGM_MANAGEMENT.BLOCKLIST_TITLE' })}</Label>
        <SegmentedControl
          data-testid={'add-users-control'}
          value={addCouponToBlockType}
          defaultValue={AddCouponToBlocklistEnum.CODE}
          onValueChange={(value: AddCouponToBlocklistEnum) => setAddCouponToBlockType(value)}
          options={[
            {
              label: formatMessage({ id: 'MGM_MANAGEMENT.ADD_COUPON_CODE' }),
              value: AddCouponToBlocklistEnum.CODE,
            },
            {
              label: formatMessage({ id: 'MGM_MANAGEMENT.UPLOAD_FILE' }),
              value: AddCouponToBlocklistEnum.CSV,
            },
          ]}
        />

        {addCouponToBlockType === AddCouponToBlocklistEnum.CODE && <CodeMode />}
        {addCouponToBlockType === AddCouponToBlocklistEnum.CSV && <FileMode />}

        <ActionButtonsWrapper>
          <Button
            onClick={handleGoBack}
            size="medium"
            variant="secondary"
            data-testid="go-back-button"
          >
            {formatMessage({ id: 'ACTIONS.BACK' })}
          </Button>
          <Button
            onClick={handleAddBlocklist}
            size="medium"
            variant="primary"
            data-testid="add-blocklist-button"
          >
            {formatMessage({ id: 'ACTIONS.ADD_T0_BLOCKLIST' })}
          </Button>
        </ActionButtonsWrapper>
      </Content>
    </Container>
  );
};

export default ManageBlocklist;
