export function EmptyBuzz() {
  return (
    <svg
      width="206px"
      height="246px"
      viewBox="0 0 206 246"
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
    >
      <title>UserFeedback/EmptyState/ZZ_Illustrations/General</title>
      <defs>
        <polygon id="path-1" points="0 0 205.7979 0 205.7979 227.25555 0 227.25555"></polygon>
        <path
          d="M1.42108547e-14,10.612 L19.801,11.928 C20.86,10.914 21.276,9.115 21.276,7.488 L21.276,7.488 C21.276,2.227 16.956,0 14,0 L14,0 C10.221,0 9.894,1.303 1.42108547e-14,10.612 L1.42108547e-14,10.612 Z"
          id="path-3"
        ></path>
        <path
          d="M0,4.896 L22.592,18.854 C25.533,17.994 28.292,13.616 28.292,9.758 L28.292,9.758 C28.292,3.934 23.304,0 18.712,0 L18.712,0 C16.465,0 13.16,1.177 0,4.896 L0,4.896 Z"
          id="path-5"
        ></path>
        <path
          d="M10.0100777,1.44300042 C3.2790777,4.14900042 -1.8279223,10.2970004 0.625077699,16.4460004 L0.625077699,16.4460004 L0.634077699,16.4690004 C6.4680777,18.3930004 23.9280777,11.3980004 27.0860777,5.96700042 L27.0860777,5.96700042 L27.1770777,5.80300042 L27.1680777,5.78000042 C25.5470777,1.77600042 21.6030777,-0.000999578188 17.1870777,-1.42108547e-14 L17.1870777,-1.42108547e-14 C14.8350777,-1.42108547e-14 12.3490777,0.504000422 10.0100777,1.44300042 L10.0100777,1.44300042 Z M32.1410777,10.2880004 L32.1350777,10.3020004 C31.9690777,10.6300004 29.0500777,16.1720004 17.7650777,20.7410004 L17.7650777,20.7410004 C6.1070777,25.3910004 0.151077699,23.1420004 0.151077699,23.1420004 L0.151077699,23.1420004 L0.152077699,23.1420004 C0.412077699,25.0570004 0.982077699,27.1940004 1.9430777,29.5860004 L1.9430777,29.5860004 C2.5000777,30.9710004 3.1500777,32.2470004 3.8710777,33.4210004 L3.8710777,33.4210004 L3.8720777,33.4210004 C5.2270777,35.6260004 6.8390777,37.4690004 8.5770777,39.0020004 L8.5770777,39.0020004 L8.5780777,39.0020004 C6.8390777,37.4690004 5.2280777,35.6260004 3.8720777,33.4220004 L3.8720777,33.4220004 C3.9750777,33.4650004 10.5490777,36.1870004 22.1200777,31.5780004 L22.1200777,31.5780004 C33.5480777,26.9460004 36.4680777,20.5140004 36.5680777,20.2880004 L36.5680777,20.2880004 L36.5690777,20.2830004 L36.5690777,20.2820004 L36.5700777,20.2810004 C36.2790777,18.9340004 35.8650777,17.5630004 35.3080777,16.1780004 L35.3080777,16.1780004 C34.3460777,13.7870004 33.2790777,11.8490004 32.1430777,10.2880004 L32.1430777,10.2880004 L32.1420777,10.2870004 L32.1410777,10.2880004 Z M8.5800777,39.0040004 C15.9250777,45.4760004 25.5410777,46.4140004 27.7290777,45.5340004 L27.7290777,45.5340004 C29.9160777,44.6560004 36.2100777,37.3240004 37.0340777,27.5690004 L37.0340777,27.5690004 C32.8090777,35.5690004 17.0970777,41.8310004 8.5800777,39.0040004 L8.5800777,39.0040004 Z"
          id="path-7"
        ></path>
        <path
          d="M0.933705321,0.008 C0.241705321,0.115 -0.135294679,1.387 0.0447053213,2.547 L0.0447053213,2.547 C0.224705321,3.708 0.893705321,4.318 1.58570532,4.21 L1.58570532,4.21 C2.27770532,4.103 2.73170532,3.32 2.55070532,2.159 L2.55070532,2.159 C2.37970532,1.052 1.69270532,0 1.02870532,0 L1.02870532,0 C0.997705321,0 0.965705321,0.003 0.933705321,0.008 L0.933705321,0.008 Z"
          id="path-9"
        ></path>
        <path
          d="M0.652465058,10.666 C0.0474650576,12.512 -0.190534942,14.699 0.169465058,17.338 L0.169465058,17.338 L0.169465058,17.339 C0.169465058,17.339 6.12546506,19.588 17.7834651,14.938 L17.7834651,14.938 C29.4174651,10.228 32.1604651,4.484 32.1604651,4.484 L32.1604651,4.484 L32.1604651,4.483 C31.3774651,3.406 30.5654651,2.514 29.7334651,1.776 L29.7334651,1.776 C28.9014651,1.04 28.0534651,0.457 27.1954651,0 L27.1954651,0 C24.3004651,5.453 6.54546506,12.609 0.652465058,10.666 L0.652465058,10.666 Z M22.1384651,25.775 C10.5674651,30.384 3.99346506,27.662 3.89046506,27.619 L3.89046506,27.619 C5.24646506,29.823 6.85746506,31.666 8.59646506,33.199 L8.59646506,33.199 L8.59746506,33.2 C17.1134651,36.03 32.8274651,29.766 37.0524651,21.765 L37.0524651,21.765 L37.0534651,21.764 C37.2474651,19.454 37.1354651,17.009 36.5884651,14.479 L36.5884651,14.479 C36.5434651,14.582 33.6814651,21.096 22.1384651,25.775 L22.1384651,25.775 Z"
          id="path-11"
        ></path>
      </defs>
      <g
        id="Seller-Management---Updated"
        stroke="none"
        strokeWidth="1"
        fill="none"
        fillRule="evenodd"
      >
        <g
          id="UserFeedback/EmptyState/Page-level/with-header-Copy-2"
          transform="translate(-20.000000, 0.000000)"
        >
          <g id="Group-64" transform="translate(20.000000, 0.000000)">
            <g id="Group-48" transform="translate(0.000000, 18.133450)">
              <g id="Group-3">
                <mask id="mask-2" fill="white">
                  <use xlinkHref="#path-1"></use>
                </mask>
                <g id="Clip-2"></g>
                <path
                  d="M102.8989,6.07975 C98.1669,6.07975 93.4339,7.29775 89.2129,9.73475 L19.7679,49.82975 C11.3259,54.70275 6.0829,63.78575 6.0829,73.53375 L6.0829,153.72175 C6.0829,163.46975 11.3259,172.55275 19.7679,177.42575 L89.2129,217.52075 C97.6549,222.39375 108.1429,222.39375 116.5849,217.52075 L186.0299,177.42575 C194.4719,172.55275 199.7149,163.46975 199.7149,153.72175 L199.7149,73.53375 C199.7149,63.78575 194.4719,54.70275 186.0299,49.82975 L116.5849,9.73475 C112.3639,7.29775 107.6309,6.07975 102.8989,6.07975 M102.8989,227.25575 C97.1149,227.25575 91.3309,225.76675 86.1719,222.78775 L16.7269,182.69375 C6.4089,176.73675 -0.0001,165.63575 -0.0001,153.72175 L-0.0001,73.53375 C-0.0001,61.61975 6.4089,50.51875 16.7269,44.56175 L86.1719,4.46775 C96.4899,-1.48925 109.3079,-1.48925 119.6259,4.46775 L189.0709,44.56175 C199.3889,50.51875 205.7979,61.61975 205.7979,73.53375 L205.7979,153.72175 C205.7979,165.63575 199.3889,176.73675 189.0709,182.69375 L119.6259,222.78775 C114.4669,225.76675 108.6829,227.25575 102.8989,227.25575"
                  id="Fill-1"
                  fill="#F0ECFC"
                  mask="url(#mask-2)"
                ></path>
              </g>
              <path
                d="M113.2242,73.39665 C112.7832,73.39665 112.3462,73.20265 112.0492,72.83065 C111.5342,72.18165 111.6432,71.23965 112.2912,70.72465 L115.8282,67.91465 C116.4772,67.39965 117.4192,67.50765 117.9342,68.15565 C118.4492,68.80365 118.3412,69.74665 117.6932,70.26165 L114.1552,73.07165 C113.8792,73.29065 113.5512,73.39665 113.2242,73.39665"
                id="Fill-4"
                fill="#F2E600"
              ></path>
              <path
                d="M117.9603,78.67985 L117.9453,78.67985 L114.3623,78.64385 C113.5353,78.63485 112.8713,77.95785 112.8793,77.12985 C112.8873,76.30685 113.5573,75.64685 114.3773,75.64685 L114.3933,75.64685 L117.9753,75.68285 C118.8033,75.69085 119.4673,76.36885 119.4583,77.19585 C119.4503,78.01885 118.7813,78.67985 117.9603,78.67985"
                id="Fill-6"
                fill="#F2E600"
              ></path>
              <path
                d="M91.4198,123.94205 C91.2498,123.94205 91.0778,123.91305 90.9088,123.85205 L86.7748,122.35305 C85.9968,122.07105 85.5948,121.21105 85.8768,120.43305 C86.1588,119.65505 87.0178,119.25305 87.7968,119.53505 L91.9308,121.03405 C92.7088,121.31605 93.1108,122.17605 92.8288,122.95405 C92.6078,123.56305 92.0328,123.94205 91.4198,123.94205"
                id="Fill-8"
                fill="#F2E600"
              ></path>
              <path
                d="M87.5855,130.23695 C86.9475,130.23695 86.3555,129.82595 86.1555,129.18495 C85.9085,128.39495 86.3485,127.55495 87.1385,127.30695 L90.9725,126.10795 C91.7635,125.86195 92.6035,126.30195 92.8505,127.09195 C93.0975,127.88195 92.6575,128.72195 91.8675,128.96995 L88.0335,130.16895 C87.8845,130.21495 87.7335,130.23695 87.5855,130.23695"
                id="Fill-10"
                fill="#F2E600"
              ></path>
              <path
                d="M131.7015,103.86515 C131.3185,103.86515 130.9345,103.71915 130.6415,103.42615 C130.0565,102.84015 130.0565,101.89215 130.6415,101.30615 L133.3655,98.58215 C133.9515,97.99715 134.9005,97.99715 135.4855,98.58215 C136.0705,99.16815 136.0705,100.11615 135.4855,100.70215 L132.7615,103.42615 C132.4685,103.71915 132.0845,103.86515 131.7015,103.86515"
                id="Fill-12"
                fill="#F2E600"
              ></path>
              <path
                d="M128.6466,102.92055 C128.5296,102.92055 128.4096,102.90655 128.2906,102.87755 C127.4866,102.68155 126.9936,101.87055 127.1896,101.06655 L128.0496,97.53955 C128.2456,96.73455 129.0556,96.24155 129.8606,96.43855 C130.6646,96.63455 131.1576,97.44555 130.9616,98.24955 L130.1016,101.77655 C129.9346,102.46155 129.3216,102.92055 128.6466,102.92055"
                id="Fill-14"
                fill="#F2E600"
              ></path>
              <path
                d="M71.9457,71.68225 C71.3627,71.68225 70.8087,71.34025 70.5657,70.77025 L69.3737,67.97325 C69.0487,67.21125 69.4027,66.33125 70.1647,66.00625 C70.9267,65.68125 71.8067,66.03625 72.1317,66.79725 L73.3237,69.59525 C73.6487,70.35725 73.2947,71.23725 72.5327,71.56225 C72.3407,71.64425 72.1417,71.68225 71.9457,71.68225"
                id="Fill-16"
                fill="#F2E600"
              ></path>
              <path
                d="M80.4573,71.43635 C80.2583,71.43635 80.0573,71.39635 79.8633,71.31235 C79.1033,70.98435 78.7533,70.10235 79.0823,69.34235 L80.3013,66.52435 C80.6303,65.76435 81.5123,65.41435 82.2713,65.74335 C83.0313,66.07235 83.3813,66.95435 83.0523,67.71335 L81.8333,70.53235 C81.5893,71.09835 81.0373,71.43635 80.4573,71.43635"
                id="Fill-18"
                fill="#F2E600"
              ></path>
              <path
                d="M70.3388,87.20315 C70.1108,87.20315 69.8798,87.15115 69.6628,87.04115 L67.6368,86.01515 C66.8988,85.64115 66.6038,84.73915 66.9778,84.00015 C67.3518,83.26215 68.2538,82.96715 68.9918,83.34115 L71.0178,84.36715 C71.7558,84.74115 72.0508,85.64315 71.6768,86.38115 C71.4128,86.90315 70.8858,87.20315 70.3388,87.20315"
                id="Fill-20"
                fill="#F2E600"
              ></path>
              <path
                d="M96.478,106.17165 L86.587,106.17165 C77.628,106.17165 70.34,98.88165 70.34,89.92265 L70.34,73.25965 C70.34,69.95665 73.018,67.27665 76.323,67.27665 C79.628,67.27665 82.306,69.95665 82.306,73.25965 L82.306,89.92265 C82.306,92.28265 84.227,94.20365 86.587,94.20365 L96.478,94.20365"
                id="Fill-22"
                fill="#7C6BAE"
              ></path>
              <path
                d="M90.9734,106.17165 L86.5864,106.17165 C77.6284,106.17165 70.3394,98.88165 70.3394,89.92265 L70.3394,73.25965 C70.3394,69.95665 73.0174,67.27665 76.3234,67.27665 C77.0964,67.27665 77.8354,67.42265 78.5154,67.69465 C76.2954,68.56765 74.7264,70.72965 74.7264,73.25965 L74.7264,89.92265 C74.7264,98.88165 82.0144,106.17165 90.9734,106.17165"
                id="Fill-24"
                fill="#625194"
              ></path>
              <path
                d="M106.6694,135.63225 L119.3544,135.63225 C127.4644,135.63225 134.0624,129.03325 134.0624,120.92425 L134.0624,105.84025 C134.0624,102.84825 131.6364,100.42225 128.6454,100.42225 C125.6534,100.42225 123.2274,102.84825 123.2274,105.84025 L123.2274,120.92425 C123.2274,123.06025 121.4914,124.79925 119.3544,124.79925 L106.6694,124.79925"
                id="Fill-26"
                fill="#7C6BAE"
              ></path>
              <path
                d="M130.7836,100.86185 C128.8526,101.68885 127.5026,103.60785 127.5026,105.83985 L127.5026,120.92385 C127.5026,123.05985 125.7656,124.79885 123.6296,124.79885 L119.3546,124.79885 C121.4916,124.79885 123.2276,123.05985 123.2276,120.92385 L123.2276,105.83985 C123.2276,102.84785 125.6536,100.42185 128.6456,100.42185 C129.4046,100.42185 130.1286,100.57785 130.7836,100.86185"
                id="Fill-28"
                fill="#625194"
              ></path>
              <path
                d="M115.3779,149.87275 L115.3779,77.14475 C115.3779,70.25375 109.7899,64.66675 102.8979,64.66675 C96.0069,64.66675 90.4199,70.25375 90.4199,77.14475 L90.4199,149.87075"
                id="Fill-30"
                fill="#7C6BAE"
              ></path>
              <path
                d="M102.8979,64.66675 C99.7639,64.66875 97.2269,70.25675 97.2269,77.14475 L97.2269,149.87075 L90.4199,149.87075 L90.4199,77.14475 C90.4199,70.25375 96.0069,64.66675 102.8979,64.66675"
                id="Fill-32"
                fill="#625194"
              ></path>
              <path
                d="M143.1067,162.58865 L62.6907,162.58865 C71.5357,154.26565 86.2457,148.81965 102.8987,148.81965 C105.9187,148.81965 108.8737,148.99965 111.7417,149.34365 C124.6937,150.88965 135.8667,155.77365 143.1067,162.58865"
                id="Fill-34"
                fill="#E5D400"
              ></path>
              <path
                d="M143.1067,162.58865 L80.3767,162.58865 C87.6167,155.77365 98.7897,150.88965 111.7417,149.34365 C124.6937,150.88965 135.8667,155.77365 143.1067,162.58865"
                id="Fill-36"
                fill="#F2E600"
              ></path>
              <path
                d="M103.1264,105.18885 C102.9574,105.18885 102.7864,105.15985 102.6184,105.09985 C101.8404,104.81985 101.4354,103.96085 101.7164,103.18185 L102.9154,99.85085 C103.1954,99.07285 104.0544,98.66885 104.8334,98.94885 C105.6114,99.22885 106.0164,100.08785 105.7354,100.86685 L104.5364,104.19785 C104.3164,104.80885 103.7404,105.18885 103.1264,105.18885"
                id="Fill-38"
                fill="#F2E600"
              ></path>
              <path
                d="M100.196,82.32995 C100.016,82.32995 99.832,82.29695 99.654,82.22795 C98.882,81.92895 98.5,81.05995 98.799,80.28795 L99.898,77.45695 C100.198,76.68595 101.066,76.30295 101.838,76.60295 C102.61,76.90195 102.992,77.76995 102.693,78.54195 L101.593,81.37295 C101.363,81.96695 100.797,82.32995 100.196,82.32995"
                id="Fill-40"
                fill="#F2E600"
              ></path>
              <path
                d="M106.0444,128.03425 C105.8754,128.03425 105.7044,128.00525 105.5364,127.94525 C104.7584,127.66525 104.3534,126.80625 104.6344,126.02725 L105.8334,122.69625 C106.1134,121.91725 106.9724,121.51325 107.7514,121.79425 C108.5294,122.07425 108.9344,122.93325 108.6534,123.71225 L107.4544,127.04325 C107.2344,127.65325 106.6584,128.03425 106.0444,128.03425"
                id="Fill-42"
                fill="#F2E600"
              ></path>
              <path
                d="M99.5285,104.65575 C98.8765,104.65575 98.2765,104.22675 98.0885,103.56875 L97.2885,100.77075 C97.0615,99.97475 97.5225,99.14475 98.3175,98.91775 C99.1135,98.68975 99.9435,99.15075 100.1705,99.94675 L100.9695,102.74475 C101.1975,103.54075 100.7365,104.36975 99.9405,104.59775 C99.8035,104.63675 99.6645,104.65575 99.5285,104.65575"
                id="Fill-44"
                fill="#F2E600"
              ></path>
              <path
                d="M96.331,82.40705 C95.679,82.40705 95.079,81.97805 94.891,81.32005 L94.091,78.52205 C93.864,77.72605 94.325,76.89605 95.12,76.66905 C95.916,76.44205 96.746,76.90205 96.973,77.69805 L97.772,80.49605 C98,81.29205 97.539,82.12105 96.743,82.34905 C96.606,82.38805 96.468,82.40705 96.331,82.40705"
                id="Fill-46"
                fill="#F2E600"
              ></path>
            </g>
            <g id="Group-51" transform="translate(109.592000, 0.000000)">
              <mask id="mask-4" fill="white">
                <use xlinkHref="#path-3"></use>
              </mask>
              <g id="Clip-50"></g>
              <polygon
                id="Fill-49"
                fill="#E5DEFC"
                mask="url(#mask-4)"
                points="-0.101 12.029 21.378 12.029 21.378 -0.102 -0.101 -0.102"
              ></polygon>
            </g>
            <g id="Group-54" transform="translate(109.036000, 5.032000)">
              <mask id="mask-6" fill="white">
                <use xlinkHref="#path-5"></use>
              </mask>
              <g id="Clip-53"></g>
              <polygon
                id="Fill-52"
                fill="#F0ECFC"
                mask="url(#mask-6)"
                points="-0.102 18.956 28.394 18.956 28.394 -0.101 -0.102 -0.101"
              ></polygon>
            </g>
            <g id="Group-57" transform="translate(83.693922, 3.606000)">
              <mask id="mask-8" fill="white">
                <use xlinkHref="#path-7"></use>
              </mask>
              <g id="Clip-56"></g>
              <polygon
                id="Fill-55"
                fill="#000000"
                mask="url(#mask-8)"
                points="-0.157922301 46.0000004 37.1350777 46.0000004 37.1350777 -1.36199958 -0.157922301 -1.36199958"
              ></polygon>
            </g>
            <g id="Group-60" transform="translate(86.472295, 11.421000)">
              <mask id="mask-10" fill="white">
                <use xlinkHref="#path-9"></use>
              </mask>
              <g id="Clip-59"></g>
              <polygon
                id="Fill-58"
                fill="#FFFFFF"
                mask="url(#mask-10)"
                points="-0.237294679 4.419 2.83270532 4.419 2.83270532 -0.202 -0.237294679 -0.202"
              ></polygon>
            </g>
            <g id="Group-63" transform="translate(83.675535, 9.409000)">
              <mask id="mask-12" fill="white">
                <use xlinkHref="#path-11"></use>
              </mask>
              <g id="Clip-62"></g>
              <polygon
                id="Fill-61"
                fill="#FFFF00"
                mask="url(#mask-12)"
                points="-0.139534942 36.131 37.3484651 36.131 37.3484651 -0.101 -0.139534942 -0.101"
              ></polygon>
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
