import { authMiddleware, orchestratorGraphqlUrl, orchestratorLink } from '@/Api/graphql/graphql';
import { UPDATE_REWARD_COUPON_DETAILS_MUTATION } from '@/Api/graphql/mutations/updateRewardCouponDetails';
import { GET_REWARD_COUPON_DETAILS_QUERY } from '@/Api/graphql/queries/getRewardCouponDetails';
import { GET_RWC_FULL_LOG } from '@/Api/graphql/queries/getRWCFullLog';
import { GET_RWC_LOG_LIST_QUERY } from '@/Api/graphql/queries/getRWCLogList';
import CountryCard from '@/components/CountryCard/CountryCard';
import CouponReferralAndRewardMainInfoCard from '@/components/CouponReferralAndRewardMainInfoCard/CouponReferralAndRewardMainInfoCard';
import mgmTemplateSchema from '@/components/CouponReferralAndRewardMainInfoCard/MGMTemplateSchema';
import LastAlterations from '@/components/LastAlterations/LastAlterations';
import PageHeader from '@/components/PageHeader/PageHeader';
import { COUPON_ROUTES } from '@/config/constants';
import useCountries from '@/hooks/useCountries/useCountries';
import { useFilterStore } from '@/stores/coupon/useFilterStore';
import { RewardCouponTemplate } from '@/types/mgmTemplate.types';
import { RewardPayload } from '@/utils/referralAndRewardPayload';
import { concat, useApolloClient, useLazyQuery, useMutation, useQuery } from '@apollo/client';
import { yupResolver } from '@hookform/resolvers/yup';
import { TypeToast, useToast } from 'admin-portal-shared-services';
import { useEffect, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { Container, Content } from './CouponRewardPage.styles';

const CouponRewardPage = () => {
  const PAGE_SIZE_DEFAULT = 10;
  const client = useApolloClient();
  const { formatMessage } = useIntl();
  const { countries } = useCountries();
  const { country, setCountry } = useFilterStore();
  const toastService = useToast();
  const [minimumValueForOrder, setMinimumValueForOrder] = useState(0);

  const methods = useForm<RewardCouponTemplate>({
    resolver: yupResolver(mgmTemplateSchema({ formatMessage, minimumValueForOrder })),
    mode: 'onChange',
  });

  const {
    formState: { isValid },
    reset,
    getValues,
  } = methods;

  const [page, setPage] = useState(0);
  const [pageSize, setPageSize] = useState(PAGE_SIZE_DEFAULT);
  const pageBreadcrumbs = [
    {
      name: formatMessage({ id: `HOMEPAGE.TITLE` }),
      active: false,
      href: COUPON_ROUTES.HOME,
    },
    {
      name: formatMessage({ id: `MGM.BREADCRUMBS` }),
      active: false,
      href: COUPON_ROUTES.MGM,
    },
    {
      name: formatMessage({ id: `REFERRAL_REWARD_COUPON.BREAD_CRUMB_NAME` }),
      active: true,
      href: COUPON_ROUTES.MGM_REWARD_COUPON,
    },
  ];

  const resetForm = () => {
    const template = data?.getRwcCouponConfigsByCountry;
    setMinimumValueForOrder(template?.restrictions?.minimumValueForOrder ?? 0);
    reset(template);
  };

  const {
    loading: isLoadingConfigs,
    error: errorConfigs,
    data,
    refetch: refetchGetConfigs,
  } = useQuery(GET_REWARD_COUPON_DETAILS_QUERY, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    onError() {
      toastService.notify({
        message: formatMessage({ id: 'REFERRAL_REWARD_COUPON.ERROR.LOAD_TEMPLATE' }),
        type: TypeToast.ERROR,
      });
    },
  });

  const [fetchGetLogList, { loading: isLoadingLog, data: logList, error: errorLogList }] =
    useLazyQuery(GET_RWC_LOG_LIST_QUERY, {
      fetchPolicy: 'no-cache',
      notifyOnNetworkStatusChange: true,
    });

  const [fetchGetFullLog] = useLazyQuery(GET_RWC_FULL_LOG, {
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
  });

  const handleRefetch = (countryCode: string) => {
    refetchGetConfigs();
    fetchGetLogList({
      variables: { page: 0, limit: PAGE_SIZE_DEFAULT },
      context: { uri: orchestratorGraphqlUrl(countryCode) },
    });
    setPage(0);
    setPageSize(PAGE_SIZE_DEFAULT);
  };

  const logsPagination = logList?.getRwcLogList?.paginate ?? {};

  useEffect(() => {
    fetchGetLogList({ variables: { page, limit: pageSize } });
  }, []);

  const handleOnCountryChange = (countryCode: string) => {
    client.setLink(concat(authMiddleware, orchestratorLink(countryCode)));
    setCountry(countryCode);
    handleRefetch(countryCode);
  };

  const [updateRewardCouponDetails, { loading: isUpdateLoading }] = useMutation(
    UPDATE_REWARD_COUPON_DETAILS_MUTATION,
    {
      onError: () => {
        resetForm();
        toastService.notify({
          message: formatMessage({ id: 'REFERRAL_REWARD_COUPON.ERROR.UPDATE_TEMPLATE' }),
          type: TypeToast.ERROR,
        });
      },
      onCompleted: () => {
        handleRefetch(country ?? '');
        toastService.notify({
          message: formatMessage({ id: 'REFERRAL_REWARD_COUPON.SUCCESS.COUPON_UPDATED' }),
          type: TypeToast.SUCCESS,
        });
      },
    }
  );

  const updateTemplate = async () => {
    const formData = getValues();
    const newTemplate = RewardPayload(formData);

    await updateRewardCouponDetails(newTemplate);
  };

  useEffect(() => {
    if (data?.getRwcCouponConfigsByCountry) {
      resetForm();
    }
  }, [data]);

  const handleChangePage = (newPage: number, newPageSize: number) => {
    if (newPage - 1 !== page || newPageSize !== pageSize) {
      setPage(newPage - 1);
      setPageSize(newPageSize);
      fetchGetLogList({ variables: { page: newPage - 1, limit: newPageSize } });
    }
  };

  const getFullLog = async (id: string) => {
    const { data } = await fetchGetFullLog({ variables: { id } });
    return data?.getRwcFullLog;
  };

  return (
    <Container>
      <PageHeader
        title={formatMessage({ id: `REFERRAL_REWARD_COUPON.REWARD_PAGE_TITLE` })}
        breadcrumbs={pageBreadcrumbs}
      />
      <FormProvider {...methods}>
        <form>
          <Content>
            <CountryCard
              countries={countries}
              currentCountry={country}
              onCountryChange={handleOnCountryChange}
            />
            <CouponReferralAndRewardMainInfoCard
              couponTemplate={data?.getRwcCouponConfigsByCountry}
              isLoading={isLoadingConfigs}
              hasError={Boolean(errorConfigs)}
              disableActions={isUpdateLoading || !isValid}
              onUpdateTemplate={updateTemplate}
              onCancelEdit={resetForm}
              minimumValueForOrder={minimumValueForOrder}
            />
            <LastAlterations
              pagination={{
                total: logsPagination.total ?? 0,
                pageSize,
                onChange: handleChangePage,
                current: page + 1,
              }}
              isLoading={isLoadingLog}
              hasError={Boolean(errorLogList)}
              data={logList?.getRwcLogList?.logs ?? []}
              getFullLog={getFullLog}
            />
          </Content>
        </form>
      </FormProvider>
    </Container>
  );
};

export default CouponRewardPage;
