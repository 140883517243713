import { CouponTypeMutationEnum } from '@/types/coupon.types';
import { IntlShape } from 'react-intl';
import * as yup from 'yup';

type MessageFormatter = IntlShape['formatMessage'];

export const isPercentOff = (couponType: CouponTypeMutationEnum): boolean =>
  couponType === CouponTypeMutationEnum.PERCENT_OFF;

export const isAmountOff = (couponType: CouponTypeMutationEnum): boolean =>
  couponType === CouponTypeMutationEnum.AMOUNT_OFF;

interface MGMTemplateSchemaParams {
  formatMessage: MessageFormatter;
  minimumValueForOrder: number;
}

const mgmTemplateSchema = ({ formatMessage, minimumValueForOrder }: MGMTemplateSchemaParams) =>
  yup.object({
    title: yup
      .string()
      .max(50)
      .required(formatMessage({ id: 'FIELDS.REQUIRED.GENERIC' })),
    description: yup
      .string()
      .required(formatMessage({ id: 'FIELDS.REQUIRED.ADDITIONAL_INFORMATION' }))
      .max(2000, formatMessage({ id: 'FIELDS.REQUIRED.LONG_TEXT' }, { max: '2.000' })),
    restrictions: yup.object().shape({
      couponType: yup
        .string()
        .typeError(formatMessage({ id: 'FIELDS.REQUIRED.GENERIC' }))
        .oneOf([CouponTypeMutationEnum.PERCENT_OFF, CouponTypeMutationEnum.AMOUNT_OFF])
        .required(formatMessage({ id: 'FIELDS.REQUIRED.GENERIC' })),
      couponValue: yup
        .number()
        .required(formatMessage({ id: 'FIELDS.REQUIRED.GENERIC' }))
        .typeError(formatMessage({ id: 'FIELDS.FORMAT.GENERIC' }))
        .when('couponType', {
          is: isAmountOff,
          then: (schema) =>
            schema
              .min(1, formatMessage({ id: 'FIELDS.RANGE_NUMBER' }, { min: '1', max: '999999' }))
              .max(
                999999,
                formatMessage({ id: 'FIELDS.RANGE_NUMBER' }, { min: '1', max: '999999' })
              ),
        })
        .when('couponType', {
          is: isPercentOff,
          then: (schema) =>
            schema
              .min(1, formatMessage({ id: 'FIELDS.RANGE_NUMBER' }, { min: '1', max: '100' }))
              .max(100, formatMessage({ id: 'FIELDS.RANGE_NUMBER' }, { min: '1', max: '100' })),
        }),
      orderMinimum: yup
        .number()
        .typeError(
          formatMessage({ id: 'FIELDS.RANGE_NUMBER' }, { min: minimumValueForOrder, max: '999999' })
        )
        .required(
          formatMessage({ id: 'FIELDS.RANGE_NUMBER' }, { min: minimumValueForOrder, max: '999999' })
        )
        .min(
          minimumValueForOrder,
          formatMessage({ id: 'FIELDS.RANGE_NUMBER' }, { min: minimumValueForOrder, max: '999999' })
        )
        .max(
          999999,
          formatMessage({ id: 'FIELDS.RANGE_NUMBER' }, { min: minimumValueForOrder, max: '999999' })
        ),
      maxDiscount: yup
        .number()
        .transform((value) => (Number.isNaN(value) ? undefined : value))
        .when('couponType', {
          is: isAmountOff,
          then: (schema) => schema.nullable(),
        })
        .when('couponType', {
          is: isPercentOff,
          then: (schema) =>
            schema
              .typeError(formatMessage({ id: 'FIELDS.RANGE_NUMBER' }, { min: '1', max: '999999' }))
              .required(formatMessage({ id: 'FIELDS.RANGE_NUMBER' }, { min: '1', max: '999999' }))
              .min(1, formatMessage({ id: 'FIELDS.RANGE_NUMBER' }, { min: '1', max: '999999' }))
              .max(
                999999,
                formatMessage({ id: 'FIELDS.RANGE_NUMBER' }, { min: '1', max: '999999' })
              ),
        }),
    }),
  });

export default mgmTemplateSchema;
