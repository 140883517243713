import { gql } from '@apollo/client';

export const GET_REWARD_COUPON_DETAILS_QUERY = gql`
  query GetRwcCouponConfigsByCountry {
    getRwcCouponConfigsByCountry {
      title
      description
      restrictions {
        couponType
        couponValue
        orderMinimum: minimumOrder
        maxDiscount
        monthOfExpire
        minimumValueForOrder
      }
    }
  }
`;
