import { Input as _Input } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const inputDefaultStyle = {
  height: '8px',
  width: 'calc(100% - 32px)',
  marginTop: '0.25rem',
};

export const Input = styled(_Input, {
  '&[type="number"]::-webkit-inner-spin-button, &[type="number"]::-webkit-outer-spin-button': {
    '-webkit-appearance': 'none',
    margin: 0,
  },
  '&[type="number"]': {
    '-moz-appearance': 'textfield',
  },
});
