import InfoField from '@/components/InfoField/InfoField';
import { CouponTemplate } from '@/types/mgmTemplate.types';
import { Heading } from '@hexa-ui/components';
import { useIntl } from 'react-intl';
import {
  InfoWrapper,
  RestrictionsWrapper,
  ViewContainer,
} from './CouponReferralAndRewardMainInfoCard.styles';
import useMGMTemplateRestrictions from './useMGMTemplateRestrictions';

interface ViewInfo {
  couponTemplate: CouponTemplate;
}

const ViewInfo = ({ couponTemplate }: ViewInfo) => {
  const { formatMessage } = useIntl();
  const { restrictions } = useMGMTemplateRestrictions({ couponTemplate });

  return (
    <ViewContainer>
      <InfoField
        title={formatMessage({ id: 'REFERRAL_REWARD_COUPON.TITLE' })}
        text={couponTemplate.title || ''}
      />
      <InfoField
        title={formatMessage({ id: 'REFERRAL_REWARD_COUPON.DESCRIPTION' })}
        text={couponTemplate.description || ''}
      />
      <InfoWrapper>
        <Heading size="H4">{formatMessage({ id: 'REFERRAL_REWARD_COUPON.RESTRICTIONS' })}</Heading>
        <RestrictionsWrapper>
          {restrictions.map(({ label, testId, value }) => (
            <InfoField
              key={testId}
              dataTestId={testId}
              title={label}
              text={value?.toString() || ''}
            />
          ))}
        </RestrictionsWrapper>
      </InfoWrapper>
    </ViewContainer>
  );
};

export default ViewInfo;
