import { Button, Heading, Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const FullLogText = styled(Paragraph, {
  color: '#006EDB !important',
  fontWeight: '500 !important',
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
});

export const Container = styled('div', {
  'div[role="alertdialog"]': {
    width: '90%',
    maxWidth: '1300px !important',
  },
  'div[role="alertdialog"] > div:first-child': {
    padding: 40,
    minHeight: 515,
    margin: 0,
    display: 'flex',
    flexDirection: 'column',
  },
  '.loading-data, .error-buzz': {
    placeSelf: 'center',
    flex: 1,
  },
});

export const Title = styled(Heading, {
  display: 'flex',
  button: {
    marginLeft: 'auto',
  },
});

export const Content = styled('div', {
  display: 'flex',
  background: '#f1f1f1',
  flex: 1,
  margin: '$8 0',
  wordBreak: 'break-word',

  '& > div': {
    flex: 1,
  },
});

export const JSONTitle = styled(Paragraph, {
  backgroundColor: 'rgb(205 202 202)',
});

export const ButtonClose = styled(Button, {
  marginLeft: 'auto',
});
