import { styled } from '@hexa-ui/theme';

export const Container = styled('main', {
  paddingTop: '$6',
  width: '100%',
});

export const Content = styled('div', {
  width: '100%',
  display: 'flex',
  gap: '$10',
  flexDirection: 'column',
});

export const StatusCardWrapper = styled('div', {
  height: '500px',
});
