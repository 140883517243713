import { Check, X as Close, Edit2 } from '@hexa-ui/icons';
import { useIntl } from 'react-intl';
import { PositionButton, TextButton } from './EditActions.styles';

export interface IEditActions {
  testSufix?: string;
  isEdit: boolean;
  additionalConditional?: boolean;
  disabledEdit?: boolean;
  disabledClose?: boolean;
  disabledSave?: boolean;
  onEdit: (edit: boolean) => void;
  onClose: () => void;
  onSave: () => void;
}

const EditActions = ({
  isEdit,
  testSufix,
  additionalConditional = true,
  disabledEdit = false,
  disabledClose = false,
  disabledSave = false,
  onEdit,
  onClose,
  onSave,
}: IEditActions): React.JSX.Element => {
  const { formatMessage } = useIntl();

  return (
    <>
      {!isEdit && additionalConditional && (
        <TextButton
          size="small"
          type="button"
          iconPosition="leading"
          data-testid={testSufix ? `${testSufix}-edit-button` : "edit-button"}
          className="edit-button"
          icon={Edit2}
          disabled={disabledEdit}
          onClick={(e) => {
            e.preventDefault()
            onEdit(true)
          }}
        >
          {formatMessage({ id: 'ACTIONS.EDIT' })}
        </TextButton>
      )}

      {isEdit && (
        <PositionButton>
          <TextButton
            type="button"
            data-testid="close-button"
            className="close-button"
            iconPosition="leading"
            icon={Close}
            disabled={disabledClose}
            onClick={(e) => {
              e.preventDefault()
              onClose()
            }}
          >
            {formatMessage({ id: 'ACTIONS.CANCEL' })}
          </TextButton>
          <TextButton
            size="small"
            type="submit"
            iconPosition="leading"
            data-testid="save-button"
            className="save-button"
            icon={Check}
            disabled={disabledSave}
            onClick={(e) => {
              e.preventDefault()
              onSave()
            }}
          >
            {formatMessage({ id: 'ACTIONS.SAVE' })}
          </TextButton>
        </PositionButton>
      )}
    </>
  );
};

export default EditActions;
