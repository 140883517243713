import { gql } from '@apollo/client';

export const UPDATE_REFERRAL_COUPON_DETAILS_MUTATION = gql`
  mutation (
    $title: String!
    $description: String!
    $restrictions: RcCouponConfigRestrictionsParams!
  ) {
    updateRcCouponConfig(
      params: { title: $title, description: $description, restrictions: $restrictions }
    ) {
      title
      description
      restrictions {
        couponType
        couponValue
        orderMinimum
        maxDiscount
      }
    }
  }
`;
