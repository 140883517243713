/**
 * Convert the First Letter of a String into UpperCase, and the rest in LowerCase
 * @param {string} value
 * @returns Capitalized value
 */
export const capitalizeString = (value: string): string => {
  if (!value) return '';
  return value.charAt(0).toUpperCase() + value.slice(1).toLowerCase();
};

/**
 * Generate a safe random UUUID V4
 * @returns string UUID V4
 */
export const randomUUIDV4 = () => {
  return '10000000-1000-4000-8000-100000000000'.replace(/[018]/g, (c: string) =>
    (Number(c) ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (Number(c) / 4)))).toString(
      16
    )
  );
};

/**
 * Convert string in lowercase and removes the leading and trailing white space
 * @param {string} value
 * @returns normalized string
 */
export const normalizeString = (value: string): string => {
  return value.toLowerCase().trim();
};

/**
 * Verify if a value is undefined
 * @param {unknown} value
 * @returns {Boolean} true or false
 */
export const isUndefined = (value: unknown): boolean => {
  return typeof value === 'undefined' || value === undefined;
};

export const getValueOrNull = (
  condition: boolean,
  value: string | null | number
): string | null | number => {
  return condition ? value : null;
};
