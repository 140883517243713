import { Card as _Card, Paragraph as _Paragraph } from '@hexa-ui/components';
import { keyframes, styled } from '@hexa-ui/theme';

const fadeIn = keyframes({
  '0%': { transform: 'scale(0)', opacity: 0 },
  '100%': { transform: 'scale(1)', opacity: 1 },
});

export const Container = styled(_Card, {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '$8',
  minHeight: '445px',
  '.loading-data': {
    placeSelf: 'center',
    marginTop: 'auto',
    marginBottom: 'auto',
  },
  '.error-buzz': { flex: 1 },
});

export const InfoWrapper = styled('div', {
  marginTop: '$10',
});

export const RestrictParagraph = styled(_Paragraph, {
  fontWeight: '40px',
});

export const RestrictionsWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$10',
  marginTop: '$6',
  alignItems: 'flex-end',

  '& > div': {
    width: 'fit-content',
    animation: `${fadeIn} 300ms ease-in-out`,
  },
});

export const TypesCardWrapper = styled('div', {
  display: 'flex',
  flexWrap: 'wrap',
  gap: '$4',
  '& > div': {
    padding: '0px',
    margin: '0px',
  },
});

export const InputsWrapper = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
});

export const EditBottomWrapper = styled('div', {
  marginTop: '$10',
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
});

export const ViewContainer = styled('div', {
  p: {
    wordBreak: 'break-all',
  },
});
