import { Heading as _Heading, Paragraph as _Paragraph, TextButton as _TextButton } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Content = styled('div', {
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  alignContent: 'center',
  textAlign: 'center',
  width: '100%',
});

export const Heading = styled(_Heading, {
  textAlign: 'center',
  marginBottom: '8px',
  marginTop: '16px',
});

export const TextButton = styled(_TextButton, {
  '&.blue': {
    color: '#0363C4'
  }
});

export const LoadingContainer = styled('div', {
  marginTop: '36px'
})

export const Paragraph = styled(_Paragraph, {
  marginBottom: '24px',
  textAlign: 'center',

  variants: {
    displayGray: {
      true: {
        color: '$interfaceLabelSecondary'
      },
      false: {
        color: '$interfaceLabelPrimary'
      }
    }
  }
})