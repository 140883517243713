import { TextButton as _TextButton } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const TextButton = styled(_TextButton, {
    paddingLeft: '8px',
    width: 'fit-content',

    '&.close-button': {
        marginRight: '10px'
    },

    '&.save-button': {
        marginLeft: '10px'
    }
});

export const PositionButton = styled('div', {
    display: 'flex',
    flexDirection: 'row'
});