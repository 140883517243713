import { InputProps } from '@hexa-ui/components';
import React, { ChangeEvent, forwardRef } from 'react';
import { useIntl } from 'react-intl';
import { Input, inputDefaultStyle } from './inputField.styles';

interface IInputField extends InputProps {
  value: string | number;
  name?: string;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  hint?: string;
  label?: string;
  placeholder?: string;
  optionalText?: string;
  required?: boolean;
  width?: string;
  dataTestId?: string;
  style?: React.CSSProperties;
  ariaLabel?: string;
  hasError?: boolean;
  errorText?: string;
  maxLength?: number;
  characterCounter?: boolean;
  pattern?: string;
  prefix?: string;
  type?: string;
}

const InputField = forwardRef((props: IInputField, ref: React.ForwardedRef<HTMLInputElement>) => {
  const {
    value,
    name,
    onChange,
    onInput,
    id,
    label,
    placeholder,
    optionalText,
    required,
    width,
    dataTestId,
    style,
    ariaLabel,
    hasError,
    errorText,
    maxLength,
    characterCounter,
    pattern,
    prefix,
    type = 'text',
    hint,
    sufix,
  } = props;

  const { formatMessage } = useIntl();

  return (
    <Input
      name={name}
      ref={ref}
      type={type}
      characterCounter={characterCounter}
      id={id}
      hint={hint}
      label={label}
      placeholder={placeholder ?? label}
      optionalText={optionalText ?? `(${formatMessage({ id: 'FIELDS.OPTIONAL' })})`}
      required={required ?? false}
      width={width ?? '100%'}
      data-testid={dataTestId ?? 'input-field'}
      style={style ?? inputDefaultStyle}
      aria-label={ariaLabel ?? label}
      value={value}
      hasError={hasError}
      errorText={errorText}
      maxLength={maxLength}
      onChange={onChange}
      onInput={onInput}
      pattern={pattern}
      prefix={prefix}
      sufix={sufix}
    />
  );
});

export default InputField;
