import TextAreaField from '@/components/TextArea/TextAreaField';
import { useIntl } from 'react-intl';
import { Hint } from '../ManageBlocklist.styles';

const CodeMode = () => {
  const { formatMessage } = useIntl();

  const handleUpdate = () => {
    // TODO add codes handler when BE is ready
  };
  return (
    <>
      <Hint data-testid="blocklist-hint">
        {formatMessage({ id: 'MGM_MANAGEMENT.BLOCKLIST_HINT_CODE' })}
      </Hint>
      <TextAreaField
        required
        dataTestId="text-area-codes"
        value=""
        label=""
        width="100%"
        onChange={handleUpdate}
      />
    </>
  );
};
export default CodeMode;
