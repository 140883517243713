import { FileUploader, FileValidated } from '@hexa-ui/components';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { Hint } from '../ManageBlocklist.styles';

const FileMode = () => {
  const [file, setFile] = useState<FileValidated[]>([]);

  const { formatMessage } = useIntl();

  const cleanFile = () => {
    setFile([]);
  };

  const handleFile = (currentFile: FileValidated[]) => {
    setFile(currentFile);
  };

  return (
    <>
      <Hint data-testid="blocklist-hint">
        {formatMessage({ id: 'MGM_MANAGEMENT.BLOCKLIST_HINT_FILE' })}
      </Hint>
      <FileUploader
        type="document"
        accept=".csv"
        maxFiles={1}
        maxFileSize={50000000}
        message={formatMessage({ id: 'ENTITIES.USER.UPLOAD_CSV_FILE_MESSAGE' })}
        buttonIntl={formatMessage({ id: 'ENTITIES.USER.UPLOAD_CSV_FILE_BUTTON' })}
        onDrop={handleFile}
        onClean={cleanFile}
        value={file}
      />
    </>
  );
};
export default FileMode;
