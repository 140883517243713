import { Card as _Card, Paragraph as _Paragraph } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Container = styled('main', {
  paddingTop: '$6',
  width: '100%',
});

export const Content = styled(_Card, {
  width: '100%',
  padding: '$8',
  display: 'flex',
  flexDirection: 'column',
  gap: '$6',
  minHeight: '600px',
});

export const Label = styled(_Paragraph, {
  fontWeight: '$semibold !important',
  fontSize: '$2',
});

export const Hint = styled(_Paragraph, {
  fontSize: '$2',
});

export const ActionButtonsWrapper = styled('div', {
  marginTop: 'auto',
  width: '100%',
  display: 'flex',
  justifyContent: 'flex-end',
  alignItems: 'center',
  gap: '$8',
});
