import { useEnvContext } from '@/components';
import permissions from '@/config/permissions';
import { useAuthenticationService, useHasPermission } from 'admin-portal-shared-services';
import { useEffect, useState } from 'react';

const useCountries = () => {
  const [countries, setCountries] = useState<string[]>([]);

  const { supportedCountries } = useEnvContext();

  const isBeesAdmin = useHasPermission(permissions.BEES_ADMIN);
  const isBeesViewer = useHasPermission(permissions.BEES_VIEWER);
  const authentication = useAuthenticationService();

  const hasPermission = isBeesAdmin || isBeesViewer;

  useEffect(() => {
    if (supportedCountries.length === 0 || !authentication) return;

    if (hasPermission) {
      setCountries(supportedCountries);
      return;
    }

    const userSupportedCountries = authentication.getSupportedCountries();
    const filteredCountries = supportedCountries.filter((country) =>
      userSupportedCountries.includes(country)
    );
    setCountries(filteredCountries);
  }, [supportedCountries, authentication]);

  return { countries };
};
export default useCountries;
