import { styled } from '@hexa-ui/theme';

export const Container = styled('main', {
  paddingTop: '$6',
  width: '100%',
});

export const Content = styled('div', {
  width: '100%',
  display: 'flex',
  gap: '$10',
  flexDirection: 'column',
});

export const CountryAndStatusWrapper = styled('section', {
  display: 'flex',
  alignItems: 'flex-start',
  gap: '$8',

  '& > div': {
    height: '164px',
    maxWidth: '50%',
  },
});

export const StatusCardWrapper = styled('div', {
  height: '500px',
});

export const LoadingWrapper = styled('div', {
  width: '100%',
  height: '50vh',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
});
