import HiddenText from '@/components/HiddenText/HiddenText';
import { IFullLog, RCDifferencesEnum } from '@/types/mgm.types';
import { getDateFnsLocale } from '@/utils/date/DateFormatters';
import { format } from 'date-fns';
import { useIntl } from 'react-intl';
import FullLog from '../FullLog/FullLog';
import { CenterColumn } from '../LastAlterations.styles';

interface useColumnsParams {
  getFullLog: (id: string) => Promise<IFullLog>;
}

const useColumns = ({ getFullLog }: useColumnsParams) => {
  const { formatMessage, locale } = useIntl();

  const columns = [
    {
      Header: formatMessage({ id: 'LAST_ALTERATIONS.TABLE.USER' }),
      accessor: 'user',
      customRender: (value: string) => {
        return <HiddenText text={value} />;
      },
    },
    {
      Header: formatMessage({ id: 'LAST_ALTERATIONS.TABLE.UPDATED_IN' }),
      accessor: 'createdAt',
      customRender: (value: string) => {
        if (!value) return <span />;
        return (
          <CenterColumn>
            {format(new Date(value), 'MMM dd, yyyy HH:mm', { locale: getDateFnsLocale(locale) })}
          </CenterColumn>
        );
      },
    },
    {
      Header: formatMessage({ id: 'LAST_ALTERATIONS.TABLE.MODIFICATION' }),
      accessor: 'differences',
      customRender: (value: RCDifferencesEnum[]) => {
        return (
          <span>
            {value
              .map((item) =>
                formatMessage({ id: 'LAST_ALTERATIONS.DIFFERENCES.' + item.toUpperCase() })
              )
              .join(', ')}
          </span>
        );
      },
    },
    {
      Header: '',
      accessor: 'id',
      customRender: (value: string) => {
        return <FullLog id={value} getFullLog={getFullLog} />;
      },
    },
  ];

  return columns;
};

export default useColumns;
