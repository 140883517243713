import { gql } from '@apollo/client';

export const GET_RWC_FULL_LOG = gql`
  query ($id: String!) {
    getRwcFullLog(id: $id) {
      id
      oldVersion {
        title
        description
        restrictions {
          couponType
          couponValue
          orderMinimum: minimumOrder
          maxDiscount
        }
      }
      newVersion {
        title
        description
        restrictions {
          couponType
          couponValue
          orderMinimum: minimumOrder
          maxDiscount
        }
      }
      createdAt
      operation
      user
    }
  }
`;
