import React from 'react';
import { TextAreaStyle } from "./TextAreaField.styles";

interface ITextAreaField {
  value: string;
  label?: string;
  id?: string;
  dataTestId?: string;
  width?: string;
  hint?: string;
  characterCounter?: boolean;
  optionalText?: string;
  hasError?: boolean;
  errorText?: string;
  onChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
  placeholder?: string;
  maxLength?: number;
  required?: boolean;
  disabled?: boolean;
  style?: React.CSSProperties;
}

const TextAreaField = ({
  value,
  label,
  id,
  dataTestId,
  width,
  hint,
  characterCounter,
  optionalText,
  hasError,
  errorText,
  onChange,
  placeholder,
  maxLength,
  required,
  disabled,
  style,
}: ITextAreaField): React.JSX.Element => {
  return (
    <TextAreaStyle
      data-testid={dataTestId ?? 'text-area-field'}
      value={value}
      label={label}
      id={id}
      width={width}
      hint={hint}
      characterCounter={characterCounter}
      optionalText={optionalText}
      hasError={hasError}
      errorText={errorText}
      onChange={onChange}
      placeholder={placeholder ?? label}
      maxLength={maxLength}
      required={required ?? false}
      disabled={disabled ?? false}
      style={style}
    />
  )
}

export default TextAreaField;