import { Card as HexaUiCard, Select as HexaUiSelect } from '@hexa-ui/components';
import { styled } from '@hexa-ui/theme';

export const Container = styled(HexaUiCard, {
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  padding: '$8',

  '& > div:first-child': {
    marginBottom: '$4',
  },
});

export const SelectField = styled('div', {
  width: '100%',

  '& > button': {
    width: 'fit-content',
  },
});

export const Select = styled(HexaUiSelect.Root, {});

export const Option = styled(HexaUiSelect.Option, {});
