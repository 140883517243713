import { Error403, LoadingBuzz } from '@hexa-ui/components';
import React, { Suspense, lazy } from 'react';
import { useIntl } from 'react-intl';
import { Route, Routes, useNavigate } from 'react-router-dom';
import Can from './components/Can/Can';
import { COUPON_ROUTES } from './config/constants';
import { TOGGLE_LIST } from './config/featureToggles';
import permissions from './config/permissions';
import CouponHomePage from './pages/CouponHomePage/CouponHomePage';
import CouponMGMPage from './pages/CouponMGMPage/CouponMGMPage';
import CouponReferralPage from './pages/CouponReferralPage/CouponReferralPage';
import CouponRewardPage from './pages/CouponRewardPage/CouponRewardPage';
import ManageBlocklist from './pages/ManageBlocklist/ManageBlocklist';

const CouponListPage = lazy(() => import('./pages/CouponListPage/CouponListPage'));
const CouponDetailsPage = lazy(() => import('./pages/CouponDetailsPage/CouponDetailsPage'));
const NewCouponPage = lazy(() => import('./pages/NewCouponPage/NewCouponPage'));
const ManageMGMCouponsPage = lazy(
  () => import('./pages/ManageMGMCouponsPage/ManageMGMCouponsPage')
);

export const fallback = (
  <div
    style={{ height: '50vh', display: 'flex', alignItems: 'flex-end', justifyContent: ' center' }}
  >
    <LoadingBuzz />
  </div>
);

const Router = (): React.JSX.Element => {
  const { formatMessage } = useIntl();
  const navigate = useNavigate();

  const errorFallback = (
    <Error403
      header={formatMessage({ id: 'UNAUTHORIZED_PAGE.TITLE' })}
      description={formatMessage({ id: 'UNAUTHORIZED_PAGE.DESCRIPTION' })}
      buttonText={formatMessage({ id: 'UNAUTHORIZED_PAGE.ACTION' })}
    />
  );

  return (
    <Suspense fallback={fallback}>
      <Can
        performs={[
          permissions.BEES_ADMIN,
          permissions.BEES_VIEWER,
          permissions.COUNTRY_MANAGER,
          permissions.COUNTRY_VIEWER,
        ]}
        fallback={errorFallback}
      >
        <Routes>
          <Route
            path={COUPON_ROUTES.HOME}
            element={
              <Suspense fallback={fallback}>
                <CouponHomePage />
              </Suspense>
            }
          />
          <Route
            path={COUPON_ROUTES.COUPON_MANAGEMENT}
            element={
              <Suspense fallback={fallback}>
                <CouponListPage />
              </Suspense>
            }
          />
          <Route
            path={COUPON_ROUTES.CREATE_COUPON}
            element={
              <Can
                performs={[permissions.BEES_ADMIN, permissions.COUNTRY_MANAGER]}
                fallback={errorFallback}
              >
                <Suspense fallback={fallback}>
                  <NewCouponPage />
                </Suspense>
              </Can>
            }
          />
          <Route
            path={COUPON_ROUTES.DETAILS_COUPON}
            element={
              <Suspense fallback={fallback}>
                <CouponDetailsPage />
              </Suspense>
            }
          />
          <Route
            path={COUPON_ROUTES.MGM}
            element={
              <Can featureToggle={TOGGLE_LIST.MGM}>
                <Suspense fallback={fallback}>
                  <CouponMGMPage />
                </Suspense>
              </Can>
            }
          />
          <Route
            path={COUPON_ROUTES.MGM_REFERRAL_COUPON}
            element={
              <Can featureToggle={TOGGLE_LIST.MGM}>
                <Suspense fallback={fallback}>
                  <CouponReferralPage />
                </Suspense>
              </Can>
            }
          />
          <Route
            path={COUPON_ROUTES.MGM_REWARD_COUPON}
            element={
              <Can featureToggle={TOGGLE_LIST.MGM}>
                <Suspense fallback={fallback}>
                  <CouponRewardPage />
                </Suspense>
              </Can>
            }
          />
          <Route
            path={COUPON_ROUTES.MGM_MANAGE}
            element={
              <Can featureToggle={TOGGLE_LIST.MGM_MANAGE_COUPONS_BY_USER}>
                <Suspense fallback={fallback}>
                  <ManageMGMCouponsPage />
                </Suspense>
              </Can>
            }
          />
          <Route
            path={COUPON_ROUTES.MGM_BLOCKLIST}
            element={
              <Can featureToggle={TOGGLE_LIST.MGM_MANAGE_COUPONS_BY_USER}>
                <Suspense fallback={fallback}>
                  <ManageBlocklist />
                </Suspense>
              </Can>
            }
          />
        </Routes>
      </Can>
    </Suspense>
  );
};
export default Router;
