import { Tooltip } from '@hexa-ui/components';
import { TooltipPlacement } from '@hexa-ui/components/dist/declarations/src/lib/tooltip/Tooltip/Tooltip.types';
import { HelpCircle } from '@hexa-ui/icons';
import Copy from '../Copy/Copy';
import { Info, InputContainer, Label, LabelContainer, Paragraph } from './InfoField.styles';

interface IInfoField {
  title: string;
  text: string | undefined;
  secondaryText?: string;
  enableCopy?: boolean;
  tooltip?: boolean;
  tipText?: string;
  tipPlacement?: TooltipPlacement;
  dataTestId?: string;
}

const InfoField = ({
  title,
  text,
  secondaryText,
  enableCopy = false,
  tooltip = false,
  tipText = '',
  tipPlacement = 'top',
  dataTestId,
}: IInfoField): React.JSX.Element => {
  return (
    <Info data-testid={dataTestId ?? 'info-field'}>
      <LabelContainer>
        <Label
          weight="semibold"
          data-testid={dataTestId ? `info-field-title-${dataTestId}` : 'info-field-title'}
        >
          {title}
        </Label>
        {tooltip && (
          <Tooltip placement={tipPlacement} text={tipText}>
            <HelpCircle size="medium" />
          </Tooltip>
        )}
      </LabelContainer>
      <InputContainer>
        <Paragraph data-testid={dataTestId ? `info-field-value-${dataTestId}` : 'info-field-value'}>
          {text || '-'}
          {enableCopy && <Copy value={text ?? ''} />}
        </Paragraph>

        {secondaryText && (
          <Paragraph>
            {secondaryText || '-'}
            {enableCopy && <Copy value={secondaryText} />}
          </Paragraph>
        )}
      </InputContainer>
    </Info>
  );
};

export default InfoField;
